import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import UserServices from '../../../services/general/userServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import ReactInputMask from 'react-input-mask';

const EditProfileModal = observer(({show, setShow, info, fetchInfo}) => {
    const [visible, setVisible] = useState(false);

    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {handleSubmit, setValue, register, control, formState: {errors}} = useForm();

    useEffect(() => {
        if(info){
            const {...rest} = info;
            for (const [key, value] of Object.entries(rest)) {
                rest[key] !== null && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const CustomSubmit = async (data) => {
        const res = await UserServices.profile.update(data);

        if (res.statusCode === 200) {
            setVisible(true);
            fetchInfo();
            setTimeout(() => {
                setVisible(false);
            }, 3000);
        }
    };

  return (
	<Modal className="modal fade theme-modal" show={show} onHide={handleClose} centered size="lg">
		<Modal.Header>
			<h5 className="modal-title">{app.localizationsItems?.edit_profile}</h5>
			<button type="button" className="btn-close" onClick={handleClose}>
				<i className="fa-solid fa-xmark"></i>
			</button>
		</Modal.Header>
        <form onSubmit={handleSubmit(CustomSubmit)}>
            <Modal.Body>
                <div className="row g-4" >
                    <div className="col-xxl-12">
                        <div className="form-floating theme-form-floating">
                            <input type="text" className="form-control" {...register('name')}/>
                            <label>{app.localizationsItems?.name}</label>
                        </div>
                    </div>
                    <div className="col-xxl-12">
                        <div className="form-floating theme-form-floating">
                            <input type="email" className="form-control" {...register('email')}/>
                            <label>{app.localizationsItems?.email} </label>
                        </div>
                    </div>
                    <div className="col-xxl-12">
                        <div className="form-floating theme-form-floating">
                            <Controller defaultValue="" name="phone" control={control}
                                render={({field: {onChange, value}}) => (
                                    <ReactInputMask onChange={onChange} value={value}
                                        mask="+7(999)999-99-99" maskChar="_">
                                        {
                                            inputProps => (
                                                <input type="tel" $error={errors.phone} className="form-control" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                            )
                                        }
                                    </ReactInputMask>
                                )}
                                rules={{pattern: {
                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                }}}
                            />
                            <label>{app.localizationsItems?.phone} </label>
                        </div>
                    </div>
                    {visible && 
                        <p className='success-address'>
                            <i className='fa fa-check-circle'></i>
                            {app.localizationsItems?.new_data}
                        </p>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button className="btn btn-animation btn-md fw-bold" onClick={handleClose}>{app.localizationsItems?.cancel}</button>
                <button className="btn theme-bg-color btn-md fw-bold text-light">{app.localizationsItems?.save}</button>
            </Modal.Footer>
        </form>
	</Modal>
  )
})

export default EditProfileModal