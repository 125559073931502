import http from "./http-common"
import {getData} from "../handlers";

export default class ServiceServices {
    static index = (queryString) => {
        return getData(http, `/app/services${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/services/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static categories = (queryString) => {
        return getData(http, `/app/services/categories${queryString ? `${queryString}` : ""}`);
    }
}