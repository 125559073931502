import React from 'react'
import img from "../../images/placeholder.png"

const ExcellenceBox = ({card}) => {
  return (
    <div className="faq-top-box">
        <div className="faq-box-icon">
            <img src={card.image} alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
        </div>

        <div className="faq-box-contain">
            <h3>{card.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
        </div>
    </div>
  )
}

export default ExcellenceBox