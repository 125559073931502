import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import img from "../../images/placeholder.png"
import { useNavigate } from 'react-router-dom'

const BannerSection = ({item}) => {
	const [banner1, setBanner1] = useState();
	const [banner2, setBanner2] = useState();

    const navigate = useNavigate();

	useEffect(() => {
		if(item){
			setBanner1(item.elements[0])
			setBanner2(item.elements[1])
		}
	}, [item])

  return (
	<section className="banner-section">
		<div className="container-fluid-lg">
			<div className="row gy-xl-0 gy-3">
				{banner1 &&
					<div className="col-xl-6">
						<div className="banner-contain-3 hover-effect">
							<img src={banner1.image} className="bg-img img-fluid position-absolute" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
							<div className="banner-detail banner-details-dark text-white p-center-left w-50 position-relative mend-auto">
								<div style={{color: banner1.banner_font_color}}>
									<h6 className="ls-expanded text-uppercase">{banner1.subtitle_top}</h6>
									<h3 className="mb-sm-3 mb-1">{banner1.title}</h3>
									<h4>{banner1.description}</h4>
									{banner1.link && <button onClick={() => navigate(banner1.link)} className="btn theme-color bg-white btn-md fw-bold mt-sm-3 mt-1 mend-auto">{banner1.subtitle}</button>}
								</div>
							</div>
						</div>
					</div>
				}
				{banner2 &&
					<div className="col-xl-6">
						<div className="banner-contain-3 hover-effect">
							<img src={banner2.image} className="bg-img img-fluid position-absolute" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
							<div className="banner-detail text-dark p-center-left w-50 position-relative mend-auto">
								<div style={{color: banner2.banner_font_color}}>
									<h6 className=" ls-expanded text-uppercase">{banner2.subtitle_top}</h6>
									<h3 className="mb-sm-3 mb-1">{banner2.title}</h3>
									<h4 className="text-content">{banner2.description}</h4>
									{banner2.link && <button onClick={() => navigate(banner2.link)} className="btn theme-bg-color text-white btn-md fw-bold mt-sm-3 mt-1 mend-auto">{banner2.subtitle}</button>}
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	</section>
  )
}

export default BannerSection