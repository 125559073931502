import React, { useContext, useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import WishProductCard from '../simple/WishProductCard'
import Pagination from './Pagination'
import UserServices from '../../services/general/userServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const WishlistContainer = observer(() => {
    const {app, user} = useContext(Context);

    const {
        items,
        fetchItems,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.favorites.index, user.wishlistUid)

    useEffect(() => {
        if(items){
            if(items.uid && (items.uid !== user.wishlistUid)){
                user.setWishlistUid(items.uid);
            }
        }
    }, [items])

  return (
	<section className="wishlist-section section-b-space">
		<div className="container-fluid-lg">
            {items?.items ?
                <div className="row g-sm-3 g-2">
                    {items?.items?.map((card, index) =>
                        <div className="col-xxl-2 col-lg-3 col-md-4 col-6 product-box-contain" key={index}>
                            <WishProductCard card={card} fetchItems={fetchItems}/>
                        </div>
                    )}
                    <div className='custome-pagination'>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </div>
                </div>
                :
                <div className='text-center'>
                    <h3>{app.localizationsItems?.wishlist_title}</h3>
                </div>
            }
        </div>
    </section>
  )
})

export default WishlistContainer