import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CatalogServices from '../../services/general/catalogServices';
import { toast } from 'react-toastify';

const StickyMenu = observer(({item}) => {
    const {app, productCategories} = useContext(Context)

    const fetchCategories = async () => {
        const res = await CatalogServices.categories.index();

        if(res.statusCode === 200){
            productCategories.setCategories(res.content?.items);
        }else{
            toast.error(res.message);
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [])

  return (
    <div className="header-nav-left">
        <button className="dropdown-category dropdown-category-2">
            <i className="iconly-Category icli"></i>
            <NavLink to={`/${app.lang}/catalog`}>{app.localizationsItems?.catalog}</NavLink>
        </button>

        <div className="category-dropdown">
            <div className="category-title">
                <h5></h5>
                <button type="button" className="btn p-0 close-button text-content">
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            {productCategories.categories &&
                <ul className="category-list">
                    {productCategories.categories.map((it, index) => 
                        <li className="onhover-category-list" key={index}>
                            <NavLink to={`/${app.lang}/catalog/${it.slug}`} className="category-name">
                                <h6>{it.title}</h6>
                                <i className="fa-solid fa-angle-right"></i>
                            </NavLink>
                            {it.children?.length >= 1 &&
                                <div className="onhover-category-box">
                                    {it.children.map((el, index) => 
                                        <div className="list-1" key={index}>
                                            <NavLink className="category-title-box" to={`/${app.lang}/catalog/${el.slug}`}>
                                                <h5>{el.title}</h5>
                                            </NavLink>
                                            <ul className='mb-3'>
                                                {el.children?.map((elem, index) =>
                                                    <li key={index}>
                                                        <NavLink to={`/${app.lang}/catalog/${it.slug}`}>{elem.title}</NavLink>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            }
                        </li>
                    )}
                </ul>
            }
        </div>
    </div>
  )
})

export default StickyMenu