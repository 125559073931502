import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import CatalogServices from '../../services/general/catalogServices';
import Utils from '../../services/utils';
import { NavLink, Route, Routes } from 'react-router-dom';
import DynaminPaginationPage from './DynaminPaginationPage';
import Slider from "react-slick";

const DynamicPaginationProducts = observer(() => {
    const {app, productCategories} = useContext(Context);

    const [loading, setLoading] = useState();

    const fetachcategories = async () => {
        if(productCategories.categories.length < 1){
            setLoading(true)
            const res = await CatalogServices.categories.index();

            if((res.statusCode === 200) && res.content){
                res.content.items && productCategories.setCategories(res.content.items);
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        fetachcategories();
    }, [])

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        swipeToSlide: true,
    }

  return (
	<section className={loading ? "product-section section-b-space isLoading" : "product-section section-b-space"}>
        {productCategories.categories.length >= 1 &&
            <div className="container-fluid-lg">
                <Slider className="new-arrow-slider2 tab-style-color mb-3" {...settings}>
                        <li className="nav-item">
                            <NavLink to={`/${app.lang}/category/all`} className="nav-link btn">{app.localizationsItems?.all_text}</NavLink>
                        </li>
                        {productCategories.categories.map((it, index) =>
                            <li className="nav-item" key={index}>
                                <NavLink to={`/${app.lang}/category/${it.slug}`} className="nav-link btn">{Utils.trimText(it.title, 20, '...')}</NavLink>
                            </li>
                        )}
                </Slider>
                <Routes>
                    <Route index element={<DynaminPaginationPage key={'all'} slug={'all'}/>}/>
                    <Route path={`category/all`} element={<DynaminPaginationPage key={'all'} slug={'all'}/>}/>
                    {productCategories.categories.map((it, index) =>
                        <Route path={`category/${it.slug}`} key={index} element={<DynaminPaginationPage key={it.slug} slug={it.slug}/>}/>
                    )}
                </Routes>
            </div>
        }
	</section>
  )
})

export default DynamicPaginationProducts