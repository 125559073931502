import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const LoyaltyModal = observer(({show, setShow, info}) => {
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div className='form-modal'>
                <h2 className='mb-4'>{app.localizationsItems?.current_level}: {info.title}</h2>
                <p>{info.description}</p>
                <div className='row justify-content-end'>
                    <div className="col-auto">
                        <button onClick={handleClose} className='btn btn-animation fw-bold ms-auto'>{app.localizationsItems?.thanks}</button>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default LoyaltyModal