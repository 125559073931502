import React, { useContext } from 'react'
import BasketContainer from '../../components/simple/BasketContainer'
import PageTop from '../../components/simple/PageTop'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const Basket = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.basket
    }

  return (
    <div>
        <PageTop item={item}/>
        <BasketContainer/>
    </div>
  )
})

export default Basket