import React, { useContext } from 'react'
import Utils from '../../../services/utils';
import { NavLink } from 'react-router-dom';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';

const OrderBox = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <NavLink className="order-box dashboard-bg-box" to={`/${app.lang}/user/orders/${card.id}`}>
        <div className="order-container">
            <div className="order-detail">
                <h4>{app.localizationsItems?.order} №{card.id} 
                    <span className='status-span'
                        style={{background: card.status?.color}}
                    >{card.status?.name}</span>
                </h4>
                <h6 className="text-content">
                    {card.description}
                </h6>
            </div>
        </div>
        <div className="product-order-text">
            <div className='date'>{card.created_at && Utils.generateOrderDate(card.created_at)}</div>
            {card.delivery && <div className="text-product"> <span>{app.localizationsItems?.delivery_variant}:</span> {card.delivery?.name} </div>}
            {card.products?.length >= 1 && <div className="text-product"> <span>{app.localizationsItems?.qty_goods}:</span> {card.products.length}</div>}
            {card.payment && <div className="text-product"> <span>{app.localizationsItems?.payment_variant}:</span> {card.payment.name}</div>}
            {card.total_price ?
                <div className="text-product"> <span>{app.localizationsItems?.total}:</span> {Utils.numberWithSpaces(card.total_price)} {app.localizationsItems?.currency}</div>
                :<></>    
            }
            {card.address?.full_address && <div className="text-product"> <span>{app.localizationsItems?.address}:</span> {card.address.full_address}</div>}
        </div>
    </NavLink>
  )
})

export default OrderBox