import { observer } from 'mobx-react-lite';
import React, { useContext} from 'react'
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import img from "../../images/placeholder.png"

const CatalogCategories = observer(({categories}) => {
    const {app} = useContext(Context);

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1745,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 692,
                settings: {
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 482,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

  return (
    categories?.length >= 1 &&
    <div className="row margin-top-categories">
        <div className="col-12">
                {/*<Slider className="category-slider new-arrow-slider mb-3" {...settings}>
                    {categories.map((card, index) =>
                        <div key={index}>
                            <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="category-box wow fadeInUp">
                                <div>
                                    <img src={card.image ? card.image : img} alt={card.title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                    <h5>{card.title}</h5>
                                </div>
                            </NavLink>
                        </div>
                    )}
                </Slider>*/}
                <div className="slider-9 mb-3">
                    {categories.map((card, index)=>
                        <div key={index}>
                            <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="category-box wow fadeInUp">
                                <div>
                                    <img src={card.image} alt={card.title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                    <h5>{card.title}</h5>
                                </div>
                            </NavLink>
                        </div>
                    )}
                </div>
        </div>
    </div>
    
  )
})

export default CatalogCategories