import React from 'react'
import SearchSection from '../../../components/complex/SearchSection'
import ProductCard2 from '../../../components/simple/ProductCard2'
import CatalogServices from '../../../services/general/catalogServices';
import useFetchItems from '../../../hooks/useFetchItems';
import Pagination from '../../../components/complex/Pagination';

const SearchContainer = () => {
    const { 
        items,
        loading,
        params, 
        setFilterData,
        pageCount,
        handlePageClick,
        fetchItems
    } = useFetchItems(CatalogServices.products.index);

  return (
    <>
        <SearchSection setFilterData={setFilterData}/>

        <section className={loading ? "section-b-space isLoading" : "section-b-space"}>
            <div className="container-fluid-lg">
                <div className="row">
                    {items?.items?.map((card, index) =>
                        <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6  col-6 mb-3" key={index}>
                            <ProductCard2 item={card} fetchItems={fetchItems}/>
                        </div>
                    )}                    
                    <div className='custome-pagination'>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default SearchContainer