import React, { useContext } from 'react'
import CheckoutContainer from '../../components/complex/CheckoutContainer'
import PageTop from '../../components/simple/PageTop'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const Checkout = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.to_check
    }
  return (
    <div>
        <PageTop item={item}/>
        <CheckoutContainer/>
    </div>
  )
})

export default Checkout