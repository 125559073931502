import React, { useContext, useState } from 'react'
import img2 from "../../images/inner-page/user/avatar.png"
import Fancybox from '../complex/FancyBox'
import ReviewsServices from '../../services/general/reviewsServices';
import { toast } from 'react-toastify';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import img from "../../images/placeholder.png"

const ProductReviewCard = observer(({
    card
}) => {
    const {app} = useContext(Context);
    const [selectedRating, setSelectedRating] = useState(card.points);

    const stars = [1, 2, 3, 4, 5];

    const [show, setShow] = useState();
    const [feedback, setFeedback] = useState('')

    const handleSubmit = async () => {
        const res = await ReviewsServices.reply(card.id, {text: feedback});

        if(res.statusCode === 200){
            toast.success(app.localizationsItems?.review_moderation);
            setFeedback('');
            setShow(false)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <li>
        <div className="people-box">
            <div>
                <div className="people-image">
                    <img src={img2} className="img-fluid" alt="" loading='lazy'/>
                </div>
            </div>
            <div className="people-comment">
                <a className="name">{card.user?.name}</a>
                <div className="date-time">
                    <h6 className="text-content">{card.created_at}</h6>

                    <div className="product-rating">
                        <ul className="rating">
                            {stars.map((star, index) => 
                                <li key={index}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                                        fill={selectedRating >= star ? '#ffb321' : 'none'}
                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                        className="feather feather-star">
                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

                <div className="reply">
                    <p>{card.text}</p>
                    {card.attachments?.length >= 1 ? <Fancybox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}
                    >
                        <div className='reviews-imgs'>
                            {card.attachments.map((el, index) =>
                                <a href={el} data-width="700" data-height="700"  target="_blank" data-fancybox="gallery" key={index}>
                                    <img src={el ? el : img}
                                        alt="" 
                                        data-zoom-image={el}
                                        loading='lazy'
                                    />
                                </a>
                            )}
                        </div>
                    </Fancybox>
                    :
                    <></>}
                    <div className='reply-button' onClick={() =>setShow(true)}>{app.localizationsItems?.answer}</div>
                </div>
            </div>
        </div>
        {show && <div className='review-reply'>
            <img src={img2} className="img-fluid" alt="" loading='lazy'/>
            <div className='review-reply-content'>
                <div className='review-reply-top'>{app.localizationsItems?.review_answer}</div>
                <div className='review-reply-user'>{card.user?.name}</div>
                <textarea className='form-control' placeholder='Текст комментария' value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
                <div className='list-buttons'>
                    <button className='btn btn-sm' onClick={() =>setShow(false)}>{app.localizationsItems?.cancel}</button>
                    <button className='btn btn-sm theme-bg-color text-white' onClick={handleSubmit}>{app.localizationsItems?.send}</button>
                </div>
            </div>
        </div>}
        {card.admin_replies?.map((el, index) =>
            <div className='review-reply' key={index}>
                <img src={img2} className="img-fluid" alt="" loading='lazy'/>
                <div className='review-reply-content'>
                    <div className='review-reply-top'>{app.localizationsItems?.review_answer} {el.created_at}</div>
                    <div className='review-reply-user'>{el.user?.name}</div>
                    <p>{el.text}</p>
                </div>
            </div>
        )}
        {card.replies?.map((el, index) =>
            <div className='review-reply' key={index}>
                <img src={img2} className="img-fluid" alt="" loading='lazy'/>
                <div className='review-reply-content'>
                    <div className='review-reply-top'>{app.localizationsItems?.review_answer} {el.created_at}</div>
                    <div className='review-reply-user'>{el.user?.name}</div>
                    <p>{el.text}</p>
                </div>
            </div>
        )}
    </li>
  )
})

export default ProductReviewCard