import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop'
import ForgotPasswordContainer from './components/ForgotPasswordContainer'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const ForgotPassword = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.forgot_password
    }
  return (
    <div>
        <PageTop item={item}/>
        <ForgotPasswordContainer/>
    </div>
  )
})

export default ForgotPassword