import React, { useContext } from 'react'
import img1 from "../../images/inner-page/cover-img.jpg"
import img2 from "../../images/inner-page/user/avatar.png"
import { Context } from '../..'
import { Link, useLocation } from 'react-router-dom'

const UserDashboard = ({userData, show, setShow, setVisibleOrder}) => {
    const location = useLocation();
    const {app} = useContext(Context);

  return (
    <div className={show ? "dashboard-left-sidebar show" : "dashboard-left-sidebar"}>
        <div className="close-button d-flex d-lg-none">
            <button className="close-sidebar" onClick={() => setShow(false)}>
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
        <div className="profile-box">
            <div className="cover-image">
                <img src={img1} className="img-fluid" alt=""/>
            </div>

            <div className="profile-contain">
                <div className="profile-image">
                    <div className="position-relative">
                        <img src={img2} className="update_img" alt=""/>
                    </div>
                </div>

                <div className="profile-name">
                    <h3>{userData?.name}</h3>
                    <h6 className="text-content">{userData?.email}</h6>
                </div>
            </div>
        </div>

        <div className="nav nav-pills user-nav-pills">
            <div className="nav-item" onClick={() => setShow(false)}>
                <Link className={(location.pathname === `/${app.lang}/user` || location.pathname === `/${app.lang}/user/dashboard`) ? 'nav-link active' : 'nav-link'}  to={'dashboard'}>
                    <i className="iconly-Home icli"></i>
                    {app.localizationsItems?.dashboard}
                </Link>
            </div>

            <div className="nav-item" onClick={() => {setShow(false); setVisibleOrder(false)}}>
                <Link className={location.pathname === `/${app.lang}/user/orders` ? 'nav-link active' : 'nav-link'} to={'orders'}>
                    <i className="iconly-Bag-2 icli"></i>
                    {app.localizationsItems?.orders}
                </Link>
            </div>

            <div className="nav-item" onClick={() => setShow(false)}>
                <Link className={location.pathname === `/${app.lang}/user/wishlist` ? 'nav-link active' : 'nav-link'} to={'wishlist'}>
                    <i className="iconly-Heart icli"></i>
                    {app.localizationsItems?.wishlist}
                </Link>
            </div>

            {app.reviewEnabled ? <div className="nav-item" onClick={() => setShow(false)}>
                <Link className={location.pathname === `/${app.lang}/user/reviews` ? 'nav-link active' : 'nav-link'} to={'reviews'}>
                    <i className="iconly-Chat icli"></i>
                    {app.localizationsItems?.reviews}
                </Link>
            </div> : <></>}

            {app.bonuses ? <div className="nav-item" onClick={() => setShow(false)}>
                <Link className={location.pathname === `/${app.lang}/user/bonuses` ? 'nav-link active' : 'nav-link'} to={'bonuses'}>
                    <i className="iconly-Ticket-Star"></i>
                    {app.localizationsItems?.bonuses}
                </Link>
            </div> : <></>}
            
            <div className="nav-item" onClick={() => setShow(false)}>
                <Link className={location.pathname === `/${app.lang}/user/addresses` ? 'nav-link active' : 'nav-link'} to={'addresses'}>
                    <i className="iconly-Location"></i>
                    {app.localizationsItems?.address}
                </Link>
            </div>
            
            <div className="nav-item" onClick={() => setShow(false)}>
                <Link className={location.pathname === `/${app.lang}/user/support` ? 'nav-link active' : 'nav-link'} to={'support'}>
                    <i className="iconly-Message icli"></i>
                    {app.localizationsItems?.support}
                </Link>
            </div>
        </div>
    </div>
  )
}

export default UserDashboard