import React, { useEffect, useState } from 'react'

const FilterItem = ({card, filters, setFilters, handleFilter}) => {
    const [checked, setChecked] = useState();
    const [open, setOpen] = useState();

    useEffect(() => {
        setChecked(filters.find(item => item === card.slug) ? true : false)
    }, [filters])

    const changeFilter = () => {
        let mas = [];
        if(filters.find(it => it === card.slug)){
            let arr = filters.filter(it => it !== card.slug);
            setFilters(arr);
            mas = [...arr];
        }else{
            setFilters([...filters, card.slug]);
            mas = [...filters, card.slug];
        }

        handleFilter(mas);
    }

  return (
    <div>
        <div className="form-check ps-0 m-0 category-list-box">
            <label className="form-check-label" onClick={() => changeFilter()}>
                <div className={checked ? 'checkbox_animated clicked' : 'checkbox_animated'}></div>
                <span className="name">{card.title}</span>
            </label>
            {card.children?.length >= 1 && <div className='form-check-button' onClick={() => setOpen(!open)}>
                <i className='fa-solid fa-chevron-down'></i>
            </div>}
        </div>
        {open && <div className='filter-droupdowns'>
            {card.children?.map((it, index) =>
                <FilterItem card={it} filters={filters} setFilters={setFilters} key={index}/>
            )}
        </div>}
    </div>
  )
}

export default FilterItem