import React, { useContext } from 'react'
import { Context } from '../..';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Utils from '../../services/utils';
import ToCartModal from '../modals/ToCartModal';
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';

const WishProductCard = observer(({card, fetchItems}) => {
    const {app, user} = useContext(Context);
    const [show, setShow] = useState(false);
    const [itemSlug, setItemSlug] = useState('');
    const [loading, setLoading] = useState();

    const addToCart = (card) => {
        setShow(true);
        setItemSlug(card.slug);
    }

    const deleteItem = async () => {
        setLoading(true);
        const data = {
            uid: user.wishlistUid,
            product_id: card.id
        }
        const res = await UserServices.favorites.toggle(data);

        if(res.statusCode === 200){
            fetchItems()
        }
        setLoading(false);
    }

  return (
    <>
        <div className={loading ? "product-box-3 h-100 isLoading" : "product-box-3 h-100"}>
            <div className="product-header">
                <div className="product-image">
                    <NavLink to={`/${app.lang}/products/${card.slug}`}>
                        <img src={card.image} className="img-fluid" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
                    </NavLink>
                    {card.state && 
                        <div className="discount" style={{backgroundColor: `${card.state.subtitle}`}}>
                            <label>{card.state.name}</label>
                        </div>
                    }
                    {card.bonus && <div className='bonus-flex'>
                        <div className="bonus">
                            <label>
                                {card.bonus.number}
                                <span>{card.bonus.type == 1 ? '%' : ''}</span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                    <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                                </svg>
                            </label>
                        </div>
                    </div>}

                    <div className="product-header-top">
                        <button className="btn wishlist-button close_button" onClick={deleteItem}>
                            <i className='fa-solid fa-xmark'></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="product-footer">
                <div className="product-detail">
                    <span className="span-name">{card.parent?.title}</span>
                    <NavLink to={`/${app.lang}/products/${card.slug}`}>
                        <h5 className="name">{card.title}</h5>
                    </NavLink>
                    <h5 className="price">
                        {(!card.price || card.price == 0) ?
                            <span className="theme-color">{app.localizationsItems?.not_specified}</span>
                            :
                            <span className="theme-color">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</span>
                        }
                        {card.old_price && <del>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</del>}
                    </h5>

                    {((card.availability && (card.amount && card.amount > 0) || (card.availability && !card.amount))) ?
                        <div className="add-to-cart-box bg-white mt-2">
                            <button className="btn btn-add-cart" onClick={() => addToCart(card)}>{app.localizationsItems?.add}
                                <span className="add-icon bg-light-gray">
                                    <i className="fa-solid fa-plus"></i>
                                </span>
                            </button>
                        </div>
                        :
                        <div className="add-to-cart-box bg-white mt-2">
                            <button className="btn btn-add-cart addcart-button">{app.localizationsItems?.not_available}
                                <span>
                                    ⊘
                                </span>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
        {show && <ToCartModal show={show} setShow={setShow} slug={itemSlug}/>}
    </>
  )
})

export default WishProductCard