import React from 'react'
import img from "../../images/placeholder.png"

const TeamBox = ({card}) => {
  return (
    <div className="team-box">
        <div className="team-iamge">
            <img src={card.image} className="img-fluid" alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
        </div>

        <div className="team-name">
            <h3>{card.name}</h3>
            <h5>{card.position}</h5>
            <p dangerouslySetInnerHTML={{ __html: card?.description }}/>
        </div>
    </div>
  )
}

export default TeamBox