import React, { useContext, useEffect} from 'react'
import useFetchItems from '../../../hooks/useFetchItems';
import WishProductCard from '../../../components/simple/WishProductCard';
import UserServices from '../../../services/general/userServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import Pagination from '../../../components/complex/Pagination';

const WishList = observer(() => {
    const {app, user} = useContext(Context);

    const {
        items,
        fetchItems,
        loading,
        pageCount,
        params,
        handlePageClick,
    } = useFetchItems(UserServices.favorites.index, user.wishlistUid)

    useEffect(() => {
        if(items){
            if(items.uid && (items.uid !== user.wishlistUid)){
                user.setWishlistUid(items.uid);
            }
        }
    }, [items])

  return (
    <div className={loading ? "dashboard-wishlist isLoading height-100" : "dashboard-wishlist"}>
        <div className="title">
            <h2>{app.localizationsItems?.wishlist}</h2>
                <span className="title-leaf title-leaf-gray">
            </span>
        </div>
        {items?.items?.length >= 1 ?
            <div className="row g-sm-4 g-3">
                {items.items.map((card, index) =>
                    <div className="col-xxl-3 col-lg-6 col-md-4 col-sm-6 wishlist-product" key={index}>
                        <WishProductCard card={card} wishlistUid={'wishlistUid'} fetchItems={fetchItems}/>
                    </div>
                )}
                <div className='custome-pagination'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
            :
            <div>
                <h4>{app.localizationsItems?.empty_data}</h4>
            </div>
        }
    </div>
  )
})

export default WishList