import React, { useContext, useEffect, useState } from 'react'
import img from "../../images/placeholder.png"
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Newsletter = observer(({item}) => {
    const {app} = useContext(Context);

	const [banner1, setBanner1] = useState();
    const [visible, setVisible] = useState();

	useEffect(() => {
		if(item){
			setBanner1(item.elements[0])
		}
	}, [item])

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        const res = await AppServices.requestTemplate(data);
    
        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, 3000);
        }
    };

  return (
	banner1 &&
	<section className="newsletter-section-2 section-b-space">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
					<div className="newsletter-box hover-effect">
						<img src={banner1.image} className="img-fluid bg-img bg-img-abs" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
						<div className="row position-relative">
							<div className="col-xxl-8 col-xl-7">
								<div className="newsletter-detail p-center-left text-white">
									<div>
										<h2>{banner1.title}</h2>
										<h4>{banner1.subtitle}</h4>
										<form className="row g-2" onSubmit={handleSubmit(CustomSubmit)}>
											<div className="col-sm-10 col-12">
												<div className="newsletter-form">
													<input {...register('email')} type="email" required className="form-control" placeholder={app.localizationsItems?.email}/>
													<button type="submit" className="btn bg-white theme-color btn-md fw-500 submit-button">{app.localizationsItems?.banner_subscribe}</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
})

export default Newsletter