import React from 'react'
import img from "../../images/placeholder.png"

const Advantages = ({item}) => {
  return (
    <div className={item?.is_background ? "section-t-space footer-section-2" : "section-t-space"}>
        <div className="container-fluid-lg">
            <div className="service-section">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="service-contain">
                            {item?.elements?.map((el, index) =>
                                <div className="service-box" key={index}>
                                    <div className="service-image">
                                        <img src={el.image ? el.image : img}alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                            loading='lazy'
                                        />
                                    </div>

                                    <div className="service-detail">
                                        <h5>{el.title}</h5>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Advantages