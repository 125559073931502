import React, { useContext, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import ProjectServices from '../../services/general/projectServices';
import { Context } from '../..';
import RecentBox from './RecentBox';
import { observer } from 'mobx-react-lite';

const ProjectSidebar = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
    } = useFetchItems(ProjectServices.index)

  return (
    <div className="left-sidebar-box">
        <div className="accordion left-accordion-box mt-0">
            <div className="accordion-item">
                <h3 className='title-sidebar'>
                    {app.localizationsItems?.recent_projects}
                </h3>
                <div className="accordion-collapse">
                    <div className="accordion-body pt-0" id="panelsStayOpen-collapseOne">
                        <div className="recent-post-box">
                            {items?.items.slice(0,4).map((elem, index) => 
                                <RecentBox key={index} elem={elem} link={'projects'}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default ProjectSidebar