import React from 'react'
import Slider from "react-slick";
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/articleServices';
import BlogCard from '../simple/BlogCard'

const BlogSlider = ({item}) => {
    const { 
        items,
    } = useFetchItems(ArticleServices.index);

    const settings = {
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 3,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }

  return (
	<section className="blog-section section-b-space">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{item?.title}</h2>
			</div>

            {items?.items &&
            	<Slider className="slider-3 arrow-slider" {...settings}>
                    {items.items.map((card, index) =>
                        <BlogCard card={card} key={index} />
                    )}
                </Slider>
            }
		</div>
	</section>
  )
}

export default BlogSlider