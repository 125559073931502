import React from 'react'
import Slider from 'react-slick'
import ReviewBox from '../simple/ReviewBox'

const ReviewSection = ({elem}) => {
    const settings = {
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '200px',
        dots: true,
        responsive: [{
                breakpoint: 1524,
                settings: {
                    centerPadding: '150px',
                }
            },
            {
                breakpoint: 1291,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '100px',
                }
            },
            {
                breakpoint: 921,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '20px',
                }
            },
            {
                breakpoint: 798,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '50px',
                }
            },
            {
                breakpoint: 798,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '20px',
                }
            },
            {
                breakpoint: 434,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0',
                }
            },
        ]
    }

  return (
	<section className="review-section section-lg-space">
		<div className="container-fluid">
			<div className="about-us-title text-center">
				<h4 className="text-content">{elem.subtitle}</h4>
				<h2 className="center">{elem.title}</h2>
			</div>
			<div className="row">
				<div className="col-12">
                    {elem?.elements && 
                    	<Slider className="slider-4-half product-wrapper" {...settings}>
                            {elem?.elements?.map((card, index) =>
                                <ReviewBox card={card} key={index}/>
                            )}
                        </Slider>
                    }
				</div>
			</div>
		</div>
	</section>
  )
}

export default ReviewSection