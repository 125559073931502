import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form';
import { Context } from '../..';
import UserServices from '../../services/general/userServices';
import { useState } from 'react';
import EditAddressModal from '../../pages/user/component/EditAddressModal';
import { observer } from 'mobx-react-lite';

const AddressBox = observer(({card, fetchItems, setAddress, deliveryZones}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const {register, formState: {errors}} = useFormContext();

    const [modalOpen, setModalOpen] = useState();

    const deleteAddress = async (e) => {
        e.stopPropagation();
        setLoading(true);

        const res = await UserServices.addresses.delete(card.id);

        if (res.statusCode === 200) {
            fetchItems();
        }
        setLoading(false);
    }   

    const editAddress = (e) => {
        e.stopPropagation();
        setModalOpen(true);

    }

  return (
    <label className={loading ? "delivery-address-box isLoading" : "delivery-address-box"}>
        <div>
            <div className="form-check">
                <input className="form-check-input" type="radio" value={card.id} name="jack" {...register('address_id')} required onClick={() => setAddress(card)}/>
            </div>

            <ul className="delivery-address-detail">
                <li>
                    <h4 className="fw-500">{card?.full_address}</h4>
                    <div className='address-icons'>
                        <i className="far fa-edit" onClick={e => editAddress(e)}></i>
                        <i className="fa fa-trash" onClick={e => deleteAddress(e)}></i>
                    </div>
                </li>
                <li>
                    <h6 className="text-content">
                        <span className="text-title">{app.localizationsItems?.apartment} :</span> {card?.json_address?.apartment || '-'}
                    </h6>
                </li>
                <li>
                    <h6 className="text-content mb-0">
                        <span className="text-title">{app.localizationsItems?.entrance} :</span>{card?.json_address?.entrance || '-'}
                    </h6>
                </li>
                <li>
                    <h6 className="text-content mb-0">
                        <span className="text-title">{app.localizationsItems?.floor} :</span>{card?.json_address?.floor || '-'}
                    </h6>
                </li>
                <li>
                    <div>
                        <div className='note'>{app.localizationsItems?.note} : {card?.json_address?.note || '-'}</div>
                    </div>
                </li>
            </ul>
        </div>
        {modalOpen && <EditAddressModal show={modalOpen} setShow={setModalOpen} id={card.id} fetchItems={fetchItems} deliveryZones={deliveryZones}/>}
    </label>
  )
})

export default AddressBox