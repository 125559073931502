import React from 'react'
import OurBusinessSlider from './OurBusinessSlider';

const OurBusiness = ({elem}) => {
  return (
	<section className="client-section section-lg-space">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
					<div className="about-us-title text-center">
						<h4>{elem.subtitle}</h4>
						<h2 className="center">{elem.title}</h2>
					</div>
                    {elem?.elements && <OurBusinessSlider elements={elem.elements}/>}
				</div>
			</div>
		</div>
	</section>
  )
}

export default OurBusiness