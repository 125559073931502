import React, { useContext, useState, useEffect } from 'react'
import { Col, Nav, Row, Tab, Tabs } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import ProductReviews from './ProductReviews';

const ProductNav = observer(({item}) => {
    const [tabs, setTabs] = useState();

    const {app} = useContext(Context);

    useEffect(() => {
        if(item){
            setTabs(item.elements?.find(it => it?.type === "tab-component")?.elements);
        }
    }, [item])

  return (
	<section>
		<div className="container-fluid-lg mb-5">
			<div className="row">
                    <div className="col-12">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tab-description0">
                            <div className='product-section-box m-0'>
                                <Nav className="nav-tabs custom-nav">
                                    <Nav.Item >
                                        <Nav.Link eventKey="tab-description0">{app.localizationsItems?.description}</Nav.Link>
                                    </Nav.Item>
                                    {tabs?.map((card, index) =>
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={`tab-description${index}6`}>{card.title}</Nav.Link>
                                        </Nav.Item>
                                    )}
                                    {app.reviewEnabled && <Nav.Item >
                                        <Nav.Link eventKey="tab-reviews">{app.localizationsItems?.reviews}</Nav.Link>
                                    </Nav.Item>}
                                </Nav>

                                <Tab.Content className="tab-content custom-tab">
                                    <Tab.Pane className="tab-pane" eventKey="tab-description0">
                                        <div className="product-description" dangerouslySetInnerHTML={{ __html: item.description}}></div>
                                    </Tab.Pane>
                                    {tabs?.map((card, index) =>
                                        <Tab.Pane className="tab-pane" eventKey={`tab-description${index}6`} key={index}>
                                            <div className="product-description" dangerouslySetInnerHTML={{ __html: card.description}}>
                                            </div>
                                        </Tab.Pane>
                                    )}
                                    {app.reviewEnabled && <Tab.Pane className="tab-pane" eventKey="tab-reviews">
                                        <ProductReviews info={item}/>
                                    </Tab.Pane>}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                
			</div>
		</div>
	</section>
  )
})

export default ProductNav

                    {/*<div className="col-12">
                        <div className="product-section-box">
                            <div className='product-section-box m-0'>
                                <ul className="nav-tabs custom-nav">
                                    <li className="nav-item" role="presentation">
										<button className="nav-link active" type="button" >{app.localizationsItems?.description}</button>
									</li>
                                    <li className="nav-item" role="presentation">
										<button className="nav-link active" type="button" >{app.localizationsItems?.reviews}</button>
									</li>
                                </ul>

                                <div className="tab-content custom-tab">
                                    <div className="tab-pane show active">
                                        <div className="product-description" dangerouslySetInnerHTML={{ __html: item.description}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>*/}