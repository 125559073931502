import http from "./http-common"
import {getData} from "../handlers";

export default class EventsServices {
    static index = (queryString) => {
        return getData(http, `/app/events${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/events/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static popular = (queryString) => {
        return getData(http, `/app/events/popular${queryString ? `&${queryString}` : ""}`);
    }

    static categories = (queryString) => {
        return getData(http, `/app/events/categories${queryString ? `${queryString}` : ""}`);
    }
}