import React, { useContext } from 'react'
import Slider from 'react-slick'
import ProductCard2 from './ProductCard2'
import CatalogServices from '../../services/general/catalogServices'
import useFetchItems from '../../hooks/useFetchItems'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const RelatedProducts = observer(({ids}) => {
    const {app} = useContext(Context);
    
    const settings = {
        arrows: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: true,
        responsive: [{
                breakpoint: 1430,
                settings: {
                    slidesToShow: 5,
                    autoplay: true,
                    autoplaySpeed: 3500,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

    const { 
        items,
    } = useFetchItems(CatalogServices.products.index, ids);

  return (
	<section className="product-list-section section-b-space">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{app.localizationsItems?.similar_products}</h2>
				<span className="title-leaf"></span>
			</div>
			<div className="row">
				<div className="col-12">
                    {items?.items &&
                        <Slider className="slider-6_1 product-wrapper" {...settings}>
                            {items.items.map((card, index) =>
                                <ProductCard2 item={card} key={index}/>
                            )}
                        </Slider>
                    }
                </div>
            </div>
        </div>
    </section>
  )
})

export default RelatedProducts