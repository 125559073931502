import React, { useContext, useEffect } from 'react'
import AboutUs from '../../components/complex/AboutUs'
import Advantages from '../../components/complex/Advantages'
import Excellences from '../../components/complex/Excellences'
import FaqComponent from '../../components/complex/FaqComponent'
import OurBlog from '../../components/complex/OurBlog'
import OurBusiness from '../../components/complex/OurBusiness'
import OurTeam from '../../components/complex/OurTeam'
import ReviewSection from '../../components/complex/ReviewSection'
import PageTop from '../../components/simple/PageTop'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { useNavigate, useParams } from 'react-router-dom'
import BannerSection from '../../components/simple/BannerSection'
import BannerSection2 from '../../components/simple/BannerSection2'
import Newsletter from '../../components/complex/Newsletter'
import DayOffer from '../../components/complex/DayOffer'
import ProductsGrid from '../../components/complex/ProductsGrid'
import TextComponent from '../../components/simple/TextComponent'
import Partners from '../../components/complex/Partners'
import BestDeals from '../../components/complex/BestDeals'
import Form from '../../components/complex/Form'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import MainBanner from '../../components/complex/MainBanner'
import CategoriesSlider2 from '../../components/complex/CategoriesSlider2'
import CategoriesSlider from '../../components/complex/CategoriesSlider'
import ProductBestsellers from '../../components/complex/ProductBestsellers'
import RecentViewProducts from '../../components/complex/RecentViewProducts'
import PopupModal from '../../components/modals/PopupModal'
import BlogSlider from '../../components/complex/BlogSlider'

const SamplePage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/page/${slug}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, slug)

  return (
    <>
    {loading ?
        <div className="fullpage-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        :
        items && <div>
            <PageTop item={items}/>
            {items.description &&
                <section className='blog-section section-b-space'>
                    <div className="container-fluid-lg">
                        <div dangerouslySetInnerHTML={{__html: items.description}}></div>
                    </div>
                </section>
            }
            {items.elements?.map((elem, index) =>
                <div key={index}>
                    {elem.type === "banners" ?
                        elem.banner_type === "main" ?
                            <MainBanner item={elem}/>
                            :
                        elem.banner_type === "best-deals" ?
                            <BestDeals elem={elem}/>
                            :
                        elem.banner_type === "banner-type-2" ?
                            <BannerSection item={elem}/>
                            :
                        elem.banner_type === "banner-type-1" ?
                            <BannerSection2 item={elem}/>
                            :
                        elem.banner_type === "advantages" ?
                            <Advantages item={elem}/>
                            :
                        elem.banner_type === "about" ?
                            <AboutUs elem={elem}/>
                            :
                            <></>
                    :
                    elem.type === "categories" ?
                        elem.categories_type === "big_images" ?
                            <CategoriesSlider2 item={elem}/>
                        :
                            <CategoriesSlider item={elem}/>
                    :
                    elem.type === "articles" ?
                        <BlogSlider item={elem}/>
                        :
                    elem.type === "products" ?
                        elem.products_type === "recommended" ?
                            <DayOffer item={elem}/>
                            :
                        elem.products_type === "bestsellers" ?
                            <ProductBestsellers item={elem}/>
                            :
                            <ProductsGrid item={elem}/>
                        :
                    elem.type === "form" ?
                        <Form item={elem}/>
                        :
                    elem.type === "popup-modal" ?
                        <PopupModal item={elem}/>
                        :
                    elem.type === "recent-products" ?
                        <RecentViewProducts item={elem}/>
                        :
                        <></>
                    }
                </div>
            )}
        </div>
    }</>
  )
})

export default SamplePage