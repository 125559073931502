import React, { useContext, useEffect } from 'react'
import Slider from "react-slick";
import OfferCard from '../simple/OfferCard';
import CatalogServices from '../../services/general/catalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const DayOffer = observer(({item}) => {
    const {app} = useContext(Context);

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    fade: true,
                }
            },
        ]
    }

    const { 
        items,
        fetchItems
    } = useFetchItems(CatalogServices.products.index, null, `type=${item.products_type}&`);

    useEffect(() => {
        if(app.cityString){
            fetchItems();
        }
    }, [app.cityString])

  return (
	<section className="deal-section section-lg-space">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{item?.title}</h2>
			</div>
			<div className="row">
				<div className="col-12">
                    {items?.items && 
                        <Slider className="three-slider-1 arrow-slider" {...settings}>
                            {items.items.map((card, index) =>
                                <OfferCard key={index} card={card}/>
                            )}
                        </Slider>}
				</div>
			</div>
		</div>
	</section>
  )
})

export default DayOffer