import React from 'react'
import Slider from 'react-slick'
import TeamBox from '../simple/TeamBox'

const OurTeamSlider = ({items}) => {
    const settings = {
        arrows: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        responsive: [{
                breakpoint: 1690,
                settings: {
                    slidesToShow: 3,
                    autoplay: true,
                    autoplaySpeed: 2000,
                }
            },
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    fade: true,
                }
            },
        ]
    }
    
  return (
    <Slider className="slider-user product-wrapper" {...settings}>
        {items?.map((card, index) =>
            <div key={index}>
                <TeamBox card={card}/>
            </div>
        )}
    </Slider>
  )
}

export default OurTeamSlider