import React from 'react'
import { Accordion } from 'react-bootstrap'

const FaqItem = ({card, index}) => {
  return (
    <Accordion.Item eventKey={`collapseOn${index}`}>
        <Accordion.Header>
            {card.title}
            <i className="fa-solid fa-angle-down"></i>
        </Accordion.Header>
        <Accordion.Body>
                <p dangerouslySetInnerHTML={{ __html: card.description }}></p>
        </Accordion.Body>
    </Accordion.Item>
  )
}

export default FaqItem