import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import Pagination from './Pagination'
import ServiceServices from '../../services/general/serviceServices'
import ServiceCard from '../simple/ServiceCard'

const ServicesConainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ServiceServices.index)

  return (
	<section className="blog-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row g-4">
                {items?.items?.map((card, index) =>
                    <div className="col-xxl-3 col-sm-6" key={index}>
                        <ServiceCard card={card}/>
                    </div>
                )}
                <div className='custome-pagination'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
			</div>
		</div>
	</section>
  )
}

export default ServicesConainer