import React, { useContext } from 'react'
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import ServiceServices from '../../services/general/serviceServices';
import RecentBox from './RecentBox';

const ServiceSidebar = () => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
    } = useFetchItems(ServiceServices.index)
    
  return (
    <div className="left-sidebar-box">
        <div className="accordion left-accordion-box mt-0">
            <div className="accordion-item">
                <h3 className='title-sidebar'>
                    {app.localizationsItems?.recent_services}
                </h3>
                <div className="accordion-collapse">
                    <div className="accordion-body pt-0" id="panelsStayOpen-collapseOne">
                        <div className="recent-post-box">
                            {items?.items.slice(0,4).map((elem, index) => 
                                <RecentBox key={index} elem={elem} link={'services'}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ServiceSidebar