import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import { Context } from '../..';
import PaymentCollapse from './PaymentCollapse';
import UserServices from '../../services/general/userServices';

const PaymentMethod = () => {
    const {app} = useContext(Context);

    const {
        items:payments,
        loading
    } = useFetchItems(UserServices.cart.order.payments)

  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-money-bill'/>
        </div>
        <div className="checkout-box">
            <div className={loading ? "checkout-title isLoading isLoading-mini" : "checkout-title"}>
                <h4>{app.localizationsItems?.payment_variant} <span className='red'>*</span></h4>
            </div>

            <div className="checkout-detail">
                <div className="accordion accordion-flush custom-accordion">
                    {payments?.map((card, index) =>
                        <PaymentCollapse card={card} key={index}/>
                    )}
                </div>
            </div>
        </div>
    </>
  )
}

export default PaymentMethod