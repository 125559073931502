import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import ProductContainer from '../../components/complex/ProductContainer';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CatalogTop from '../../components/simple/CatalogTop';

const Catalog = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {slug} = useParams();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/catalog/${slug}`);
            }
        }
    }, [app.lang])

  return (
    <div>
        <CatalogTop/>
        <ProductContainer slug={slug}/>
    </div>
  )
})

export default Catalog