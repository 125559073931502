import React from 'react'
import OurTeamSlider from './OurTeamSlider'

const OurTeam = ({elem}) => {
  return (
	<section className="team-section section-lg-space">
		<div className="container-fluid-lg">
			<div className="about-us-title text-center">
				<h4 className="text-content">{elem.subtitle}</h4>
				<h2 className="center">{elem.title}</h2>
			</div>
			<div className="row">
				<div className="col-12">
                    {elem.elements && <OurTeamSlider items={elem.elements}/>}
				</div>
			</div>
		</div>
	</section>
  )
}

export default OurTeam