import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import AppServices from '../../services/general/appServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const ProductApplicationModal = observer(({show, setShow, title}) => {
    const {app} = useContext(Context);
    
    const [visible, setVisible] = useState(false);
    const handleClose = () => setShow(false);
    const [items, setItems] = useState();
    const [loading, setLoading] = useState();

    useEffect(() => {
        if(app.settings){
            let values = app.settings.find(it => it.type === "form-modal-inputs")?.value;
            if(values){
                setItems(values)
            }else{
                setItems([app.localizationsItems?.name, app.localizationsItems?.phone])
            }
        }
    }, [app.settings])

    const {handleSubmit, register, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {
                ...data,
                Товар: title
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.request(newData);
    
        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }

        setLoading(false);
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h3 className='text-center'>{app.localizationsItems?.application_title}</h3>
                <p className='text-center'>{app.localizationsItems?.application_content}</p>
                {items &&
                    <>
                        {items?.map((card, index) =>
                            <label className='form-label w-100' key={index}>
                                {card}
                                <input type="text" className='form-control' required {...register(card)} placeholder={card}/>
                            </label>
                        )}
                    </>
                }
                <button className='btn btn-animation btn-md fw-bold ms-auto'>{app.localizationsItems?.send}</button>
            </form>

            <div className={visible ? 'form-success form-visible' : 'form-success'}>
                <i className='fa fa-check-circle'></i>
                <h2>{app.localizationsItems?.form_success_title}</h2>
                <p>{app.localizationsItems?.form_success_text}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ProductApplicationModal