import React, { useContext, useState } from 'react'
import UserServices from '../../../services/general/userServices';
import EditAddressModal from './EditAddressModal';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';

const AddressItem = observer(({card, fetchItems}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const [modalOpen, setModalOpen] = useState();

    const deleteAddress = async () => {
        setLoading(true);
        const res = await UserServices.addresses.delete(card.id);

        if (res.statusCode === 200) {
            fetchItems();
        }
        setLoading(false);
    }   

  return (
    <div className={loading ? "address-box isLoading" : "address-box"}>
        <div>
            <div className="table-responsive address-table">
                <table className="table">
                    <tbody>
                        <tr>
                            <td >{card?.full_address}</td>
                        </tr>

                        <tr>
                            <td>{app.localizationsItems?.apartment} :</td>
                            <td>{card?.json_address?.apartment || '-'}</td>
                        </tr>

                        <tr>
                            <td>{app.localizationsItems?.entrance} :</td>
                            <td>{card?.json_address?.entrance || '-'}</td>
                        </tr>

                        <tr>
                            <td>{app.localizationsItems?.floor} :</td>
                            <td>{card?.json_address?.floor || '-'}</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <div className='note'>{app.localizationsItems?.note} : {card?.json_address?.note || '-'}</div>
                </div>
            </div>
        </div>

        <div className="button-group button-icons">
            <button className="btn btn-sm add-button w-100" onClick={() => setModalOpen(true)}>
                <i className="far fa-edit"></i>
                {app.localizationsItems?.edit}
            </button>
            <button className="btn btn-sm add-button w-100" onClick={deleteAddress}>
                <i className="fa fa-trash"></i>
                {app.localizationsItems?.delete}
            </button>
        </div>
        {modalOpen && <EditAddressModal show={modalOpen} setShow={setModalOpen} id={card.id} fetchItems={fetchItems}/>}
    </div>
  )
})

export default AddressItem