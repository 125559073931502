import React, { useContext } from 'react'
import img1 from "../../../images/inner-page/forgot.png"
import { Controller, useForm } from 'react-hook-form';
import AuthServices from '../../../services/general/authServices';
import { useState } from 'react';
import ResetPasswordModal from '../../../components/modals/ResetPasswordModal';
import UpdatePasswordModal from '../../../components/modals/UpdatePasswordModal';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';

const ForgotPasswordContainer = observer(() => {
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [code, setCode] = useState();

    const {app} = useContext(Context);

    const [resetPasswordModal, setResetPasswordModal] = useState();
    const [updatePasswordModal, setUpdatePasswordModal] = useState();
    const [loading, setLoading] = useState();

    const {handleSubmit, control, register, formState: {errors}} = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.sendCode(data);

        if (res.statusCode === 200) {
            app.registerByPhone ? setPhone(data.phone) : setEmail(data.email);
            setResetPasswordModal(true);
        }else{
            toast.error(res.message)
        }
        setLoading(false);
    }

  return (
	<section className="log-in-section section-b-space forgot-section">
		<div className="container-fluid-lg w-100">
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
			<div className="row">
				<div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
					<div className="image-contain">
						<img src={img1} className="img-fluid" alt=""/>
					</div>
				</div>

				<div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
					<div className="d-flex align-items-center justify-content-center h-100">
						<div className="log-in-box">
							<div className="log-in-title">
								<h3>{app.localizationsItems?.forgot_title}</h3>
								<h4>{app.localizationsItems?.forgot_content}</h4>
							</div>

							<div className="input-box">
								<form className="row g-4" onSubmit={handleSubmit(onSubmit)}>
                                    {app.registerByPhone ?
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating log-in-form">
                                            <Controller defaultValue="" name="phone" control={control}
                                                render={({field: {onChange, value}}) => (
                                                <ReactInputMask onChange={onChange} value={value}
                                                                    mask="+7(999)999-99-99" maskChar="_">
                                                                    {
                                                                        inputProps => (
                                                                            <input type="tel" $error={errors.phone} className="form-control" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                        )
                                                                    }
                                                </ReactInputMask>
                                                        )}
                                                rules={{pattern: {
                                                    value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                                }}}
                                            />
                                            <label>{app.localizationsItems?.phone}*</label>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating log-in-form">
                                                <input type="email" {...register('email')} required className="form-control" placeholder={app.localizationsItems?.email}/>
                                                <label>{app.localizationsItems?.email}</label>
                                            </div>
                                        </div>
                                    }

									<div className="col-12">
										<button className="btn btn-animation w-100" type="submit">{app.localizationsItems?.send_code}</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        {resetPasswordModal && <ResetPasswordModal show={resetPasswordModal} setShow={setResetPasswordModal} setUpdatePasswordModal={setUpdatePasswordModal} email={email} phone={phone} setCode={setCode}/>}
        {updatePasswordModal && <UpdatePasswordModal show={updatePasswordModal} setShow={setUpdatePasswordModal} email={email} phone={phone} code={code}/>}
	</section>
  )
})

export default ForgotPasswordContainer