import React, { useContext } from 'react'
import Advantages from '../../components/complex/Advantages'
import PageTop from '../../components/simple/PageTop'
import ErrorContainer from './components/ErrorContainer'
import { Context } from '../..'

const Error = () => {
    const {app} = useContext(Context);

    const item = {
        title: 'Ошибка'
    }
  return (
    <div>
        <PageTop item={item}/>
        <ErrorContainer localizationsItems={app.localizationsItems}/>
        <Advantages/>
    </div>
  )
}

export default Error