import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import AddToBasket from '../simple/AddToBasket';
import img from "../../images/placeholder.png"
import Utils from '../../services/utils';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ToCartModal = observer(({show, setShow, slug}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const [price, setPrice] = useState();
    const [oldPrice, setOldPrice] = useState();

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

  return (
    info &&
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div>
                <div className='product-wrapper2'>
                    <img src={info.image ? info?.image : img} alt="" 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                    <div className='product-content-text'>
                        <NavLink to={`/${app.lang}/products/${info.slug}`}>
                            <h4>{info.title}</h4>
                        </NavLink>
                        <div className='product-card__availability mb-2'>
                            {app.localizationsItems?.availability}: {info.shipment &&
                            <>
                                {
                                    info.shipment.slug === "available" ?
                                        <span className="text-success">{info.shipment.name}</span>
                                    : info.shipment.slug === "on_wait" ?
                                        <span className="text-yellow">{info.shipment.name}</span>
                                    :
                                        <span className="text-danger">{info.shipment.name}</span>
                                }
                            </>}
                        </div>
                        <h4 className="price mb-5">{Utils.numberWithSpaces(price)} {app.localizationsItems?.currency}</h4>
                        <AddToBasket item={info} price={price} setPrice={setPrice} setOldPrice={setOldPrice}/>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ToCartModal