import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Context } from '../..'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import Advantages from '../../components/complex/Advantages'
import MainBanner from '../../components/complex/MainBanner'
import CategoriesSlider from '../../components/complex/CategoriesSlider'
import BannerSection from '../../components/complex/BannerSection'
import BannerSection2 from '../../components/complex/BannerSection2'
import Newsletter from '../../components/complex/Newsletter'
import BlogSlider from '../../components/complex/BlogSlider'
import DayOffer from '../../components/complex/DayOffer'
import ProductsGrid from '../../components/complex/ProductsGrid'
import AboutUs from '../../components/complex/AboutUs'
import OurTeam from '../../components/complex/OurTeam'
import ReviewSection from '../../components/complex/ReviewSection'
import FaqComponent from '../../components/complex/FaqComponent'
import OurBusiness from '../../components/complex/OurBusiness'
import Excellences from '../../components/complex/Excellences'
import TextComponent from '../../components/simple/TextComponent'
import BestDeals from '../../components/complex/BestDeals'
import Partners from '../../components/complex/Partners'
import Form from '../../components/complex/Form'
import { useNavigate, useParams } from 'react-router-dom'
import PopupModal from '../../components/modals/PopupModal'
import ProductBestsellers from '../../components/complex/ProductBestsellers'
import RecentViewProducts from '../../components/complex/RecentViewProducts'
import DynamicPaginationProducts from '../../components/complex/DynamicPaginationProducts'
import CategoriesSlider2 from '../../components/complex/CategoriesSlider2'
import AppAdvertisingBanner from '../../components/complex/AppAdvertisingBanner'

const MainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
        {loading ?
            <div className="fullpage-loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            :
            <div>
                {items &&
                    items.elements?.map((elem, index) =>
                    <div key={index}>
                        {elem.type === "banners" ?
                            elem.banner_type === "main" ?
                                <MainBanner item={elem}/>
                                :
                            elem.banner_type === "best-deals" ?
                                <BestDeals elem={elem}/>
                                :
                            elem.banner_type === "banner-type-2" ?
                                <BannerSection item={elem}/>
                                :
                            elem.banner_type === "banner-type-1" ?
                                <BannerSection2 item={elem}/>
                                :
                            elem.banner_type === "advantages" ?
                                <Advantages item={elem}/>
                                :
                            elem.banner_type === "about" ?
                                <AboutUs elem={elem}/>
                                :
                                <></>
                        :
                        elem.type === "categories" ?
                            elem.categories_type === "big_images" ?
                                <CategoriesSlider2 item={elem}/>
                            :
                                <CategoriesSlider item={elem}/>
                        :
                        elem.type === "articles" ?
                            <BlogSlider item={elem}/>
                            :
                        elem.type === "products" ?
                            elem.products_type === "recommended" ?
                                <DayOffer item={elem}/>
                                :
                            elem.products_type === "bestsellers" ?
                                <ProductBestsellers item={elem}/>
                                :
                                <ProductsGrid item={elem}/>
                            :
                        elem.type === "form" ?
                            <Form item={elem}/>
                            :
                        elem.type === "popup-modal" ?
                            <PopupModal item={elem}/>
                            :
                        elem.type === "recent-products" ?
                            <RecentViewProducts item={elem}/>
                            :
                            <></>
                        }
                    </div>
                    )
                }
                <AppAdvertisingBanner/>
            </div>
        }
    </>
  )
})

export default MainPage