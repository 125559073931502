import React from 'react'
import { Accordion } from 'react-bootstrap'
import FaqItem from '../simple/FaqItem'

const FaqComponent = ({elem}) => {
  return (
	<section className="faq-box-contain section-b-space">
		<div className="container">
			<div className="row">
				<div className="col-xl-5">
					<div className="faq-contain">
						<h2>{elem.title}</h2>
						<p dangerouslySetInnerHTML={{ __html: elem?.description }}/>
					</div>
				</div>

				<div className="col-xl-7">
					<div className="faq-accordion">
                        <Accordion defaultActiveKey={`collapseOne0`}>
                            {elem.elements?.map((card, index) =>
                                <FaqItem card={card} key={index} index={index}/>
                            )}
                        </Accordion>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default FaqComponent