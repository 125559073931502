import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop'
import LogInContainer from './components/LogInContainer'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const LogIn = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.login
    }
    
  return (
    <div>
        <PageTop item={item}/>
        <LogInContainer/>
    </div>
  )
})

export default LogIn