import React, { useEffect, useState } from 'react'

const OnBoarding = ({
    setOnBoardingActive
}) => {
    const [slideItem, setSlideItem] = useState(1);

    const [mainStyles, setMainStyles] = useState({ height: '68px' });
    const [topHeight, setTopHeight] = useState({height: '0'})

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [])


    const handleNext = () => {
        let categoryItemTop = document.querySelector('.category-item-top');
        let productCard = document.querySelector('.category-item .grid .product-card');
        let storiesBanner = document.querySelector('#stories-banner');

        if(slideItem === 1){
            if(storiesBanner){
                setSlideItem(slideItem+1)
                storiesBanner.style.zIndex = '2';
                storiesBanner.style.position = 'relative';
                setMainStyles({ height: `${storiesBanner.offsetHeight}px` });
                setTopHeight({ height: `${storiesBanner.offsetTop}px` });
            }
        }else if(slideItem === 2){
            if (categoryItemTop) {
                setSlideItem(slideItem+1)
                categoryItemTop.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest'
                });
                storiesBanner.style.zIndex = '0';
                categoryItemTop.style.position = 'relative';
                categoryItemTop.style.zIndex = '2';
                setTimeout(() => {
                    let coord = categoryItemTop.getBoundingClientRect();
                    setMainStyles({ height: `${categoryItemTop.offsetHeight}px` });
                    setTopHeight({ height: `${coord.top}px` });
                }, 500);
            }
        }else if(slideItem === 3){
            if (productCard) {
                setSlideItem(slideItem+1)
                categoryItemTop.style.zIndex = '0';
                productCard.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest'
                });
                productCard.style.zIndex = '2';
                setTimeout(() => {
                  let coord = productCard.getBoundingClientRect();
                  setMainStyles({ height: `${productCard.offsetHeight}px` });
                  setTopHeight({ height: `${coord.top}px` });
                }, 500);
            }
        }else if(slideItem === 4){
            if(productCard){
                setSlideItem(slideItem+1)
                productCard.style.zIndex = '0';
                setMainStyles({ height: `68px` });
                setTopHeight({ height: `0px` });
            }
        }else if(slideItem === 5){
            setOnBoardingActive(false)
        }
    }

    const texts = {
        1:{title: 'Поздравляем! Ваш сайт почти готов', description: 'Режим редактирования включен и вы можете настроить товары, категории и баннеры вашего сайта'},
        2:{title: 'Отредактировать баннеры', description: 'При наведении на баннер, появляются кнопки Редактировать, Удалить и Добавить баннер'},
        3:{title: 'Отредактировать категории', description: 'Нажмите кнопку Создать, если хотите добавить товар или создать новую категорию. Чтобы категория с товарами отображалась выше или ниже относительно других категорий, нажмите кнопку Переместить выше или Переместить ниже. Категория будет отображаться на сайте только в статусе “Опубликовано”'},
        4:{title: 'Отредактировать карточки товаров', description: 'При наведении на товар, появляются кнопки Редактировать, Удалить и Добавить товар. На сайте отображаются товары только со статусом “Опубликовано”'},
        5:{title: 'Всё готово', description: 'Чтобы пройти обучение еще раз, нажмите на “Как это работает?”'}
    }

  return (
        <div className='onboarding-wrapper'>
            <div className='onboarding-back-top' style={topHeight}></div>
            <div className='onboarding-wrapper-main' style={mainStyles}>
                <div className='onboarding-wrapper-content'>
                    <div className='onboarding-close' onClick={() => setOnBoardingActive(false)}>
                        <i className='icon-close'></i>
                    </div>
                    <h2>{texts[slideItem].title}</h2>
                    <p>{texts[slideItem].description}</p>
                    <div className='onboarding-wrapper-content-bottom'>
                        <div className='onboarding-btn' onClick={handleNext}>{slideItem === 5 ? 'Понятно' : 'Далее'}</div>
                        {slideItem === 5 ? <></> : <div>0{slideItem}/04</div>}
                    </div>
                </div>
            </div>
            <div className='onboarding-back-bottom'></div>
        </div>
  )
}

export default OnBoarding