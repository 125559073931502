import React, { useContext, useState } from 'react'
import DeleteModal from '../../../components/modals/DeleteModal';
import ReviewsServices from '../../../services/general/reviewsServices';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import img from "../../../images/placeholder.png"

const ReviewsBox = observer(({card, fetchItems}) => {
    const {app} = useContext(Context);
    const [selectedRating, setSelectedRating] = useState(card.points);

    const [show, setShow] = useState();

    const stars = [1, 2, 3, 4, 5];

    const deleteItem = async () => {
        const res = await ReviewsServices.delete(card.id);

        if(res.statusCode === 200){
            toast.success(res.message)
            setShow(false);
            fetchItems();
        }else{
            toast.error(res.message);
        }
    }

  return (
    <div className='order-box dashboard-bg-box mt-0'>
        <div className='d-flex mb-2'>
            <div className='text-unpublished'><i className={card.is_published ? 'fa fa-check' : 'fa fa-xmark'}></i> Опубликован</div>
            <div className='d-flex gap-2 ms-auto'>
                <div onClick={() => setShow(true)}><i className='fa fa-trash me-1'></i></div>
            </div>
        </div>
        {card.product ?
        <NavLink className='profile-review-box' to={`/${app.lang}/products/${card.product.slug}`}>
            <img src={card.product.preview_image ? card.product.preview_image : img} alt="" 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
            <div>
                <div className='d-flex gap-1 mb-1'>
                    <div className="product-rating">
                        <ul className="rating">
                            {stars.map((star, index) => 
                                <li key={index}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                                        fill={selectedRating >= star ? '#ffb321' : 'none'}
                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                        className="feather feather-star">
                                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div>{card.created_at}</div>
                </div>
                <h5>{card.product?.title}</h5>
                <div>{card.text}</div>
            </div>
        </NavLink>
        :
            <div className='profile-review-box' >
                <div>
                    <div className='d-flex gap-1 mb-1'>
                        <div>{card.created_at}</div>
                    </div>
                    <h5>{card.product?.title}</h5>
                    <div>{card.text}</div>
                </div>
            </div>
        }
        {show && 
            <DeleteModal
                show={show}
                setShow={setShow}
                deleteItem={deleteItem}
            />
        }
    </div>
  )
})

export default ReviewsBox