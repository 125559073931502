import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {
            account_info: "Информация об аккаунте",
            accrual_bonuses: "Бонусы будут начислены после получения товара",
            action: "Действие",
            action_confirmation: "Вы уверены, что хотите удалить свой профиль?",
            add: "Добавить",
            add_address: "Добавить адрес",
            add_new_address: "Добавить новый адрес",
            add_product: "Продукт добавлен в корзину",
            add_to_cart: "Добавить в корзину",
            add_to_favorites: "Добавить в избранное",
            address: "Адрес",
            address_added: "Адрес добавлен",
            all_categories: "Все категории",
            all_text: "Все",
            answer: "Ответить",
            apartment: "Квартира",
            application_content: "Наш менеджер свяжется с вами в ближайшее время",
            application_send: "Оставить заявку",
            application_title: "Оставьте заявку",
            apply: "Применить",
            as_gift: "В подарок",
            availability: "Доступность",
            back: "Назад",
            back_to_main: "Вернуться на главную страницу",
            banner_subscribe: "Подписаться",
            basket: "Корзина",
            basket_title: "Нет товаров в корзине",
            bonuses: "Бонусы",
            bonuses_used: "Использовано бонусов",
            buy_now: "Купить сейчас",
            by_together: "Вместе с этим часто покупают",
            cancel: "Отмена",
            cart: "Корзина",
            catalog: "Каталог",
            categories: "Категории",
            checkout: "Оформить заказ",
            checkout_process: "Оформить заказ",
            city: "Город",
            clear_basket: "Очистить корзину",
            clear_basket_content: "Вы уверены, что хотите очистить корзину?",
            clients_reviews: "Отзывы клиентов",
            confidentiality: "Конфиденциальность",
            confirm_password: "Подтвердите пароль",
            currency: "тг",
            dashboard: "Профиль",
            dashboard_content: "В личном кабинете содержится регистрационная информация, избранные товары, список заказов, адресов и бонусов",
            delete: "Удалить",
            delete_profile: "Удалить профиль",
            delivery: "Доставка",
            delivery_address: "Адрес доставки",
            delivery_variant: "Вариант доставки",
            delivery_yandex: "Доставка",
            description: "Описание",
            discount: "Скидка",
            edit: "Редактировать",
            edit_address: "Редактировать адрес",
            edit_profile: "Редактировать профиль",
            email: "Email",
            empty_data: "Список пуст",
            enter_code: "Введите код",
            entrance: "Подъезд",
            error_content: "Страница, которую вы ищете, не может быть найдена. Ссылка на этот адрес может быть устаревшей, или мы могли переместить её с тех пор, как вы последний раз добавляли её в закладки.",
            favorites: "Избранное",
            filter: "Фильтр",
            filter_text: "Фильтр",
            fio: "ФИО",
            floor: "Этаж",
            forgot_content: "Забыли свой пароль?",
            forgot_password: "Забыли пароль",
            forgot_title: "Добро пожаловать",
            free: "Бесплатно",
            free_delivery: "До бесплатной доставки осталось",
            general: "Общее",
            go_back_shop: "Вернуться к покупкам",
            have_account: "У вас уже есть аккаунт?",
            hot_line: "Горячая линия",
            house: "Номер дома",
            in_stock: "в наличии",
            information: "Информация",
            information_shop: "Информация о магазине",
            learn_more: "Узнать больше",
            login: "Войти",
            login_content: "Войдите в свою учетную запись",
            login_title: "Добро пожаловать",
            logout: "Выйти",
            main: "Главная",
            menu: "Меню",
            message: "Комментарий к заказу",
            minimal_sum_text: "Минимальная сумма заказа:",
            my_addresses: "Мои адреса",
            my_dashboard: "Мой профиль",
            my_orders: "Мои заказы",
            my_reviews: "Мои отзывы",
            name: "ФИО",
            new_data: "Данные обновлены",
            new_password: "Новый пароль",
            no_reviews: "Отзывов пока нет",
            not_account: "У вас нет аккаунта?",
            not_available: "нет в наличии",
            note: "Примечание",
            open_dashboard: "Открыть панель",
            order: "Заказ",
            order_accepted: "Заказ принят",
            order_content_1: "Заказ успешно оформлен",
            order_content_2: "",
            order_date: "Дата",
            order_error: "Ошибка при оформлении заказа",
            order_error_1: "При оформлении заказа произошла ошибка, с вами свяжется наш менеджер.",
            order_event: "Событие",
            order_number: "Номер заказа",
            orders: "Заказы",
            outcome: "Итог",
            password: "Пароль",
            payment_variant: "Способ оплаты",
            phone: "Номер телефона",
            phone_check_send: "На ваш номер был отправлен код",
            popular_products: "Популярные продукты",
            present_text: "В подарок",
            price: "Цена",
            primary_price: "За полотно",
            product: "Товар",
            product_delete_content: "Вы уверены, что хотите удалить товар из корзины?",
            product_information: "Информация о продукте",
            profile: "Профиль",
            promo_delivery: "Доставка по промокоду",
            promo_discount: "Скидка по промокоду",
            promo_product_content: "Товар по промокоду",
            promocode: "Промокод",
            qty: "Кол-во",
            qty_goods: "Количество товаров",
            queue: "Очередь",
            recent_events: "Последние мероприятия",
            recent_posts: "Недавние посты",
            recent_products: "Вы недавно смотрели",
            recent_projects: "Последние проекты",
            recent_services: "Последние услуги",
            regis: "Регистрация",
            regis_content: "Создать новый аккаунт",
            regis_title: "Добро пожаловать",
            register: "Зарегистрироваться",
            replenishment_bonuses: "Пополнение бонусов",
            reset: "Сбросить",
            rest_pas: "Восстановить пароль",
            restore_password: "Восстановление пароля",
            restore_password_content: "На вашу почту был отправлен код",
            review_answer: "Ответ на отзыв",
            review_moderation: "Ваш отзыв отправлен на модерацию, чуть позже он будет опубликован.",
            reviews: "Отзывы",
            save: "Сохранить",
            save_cart: "Сохранённая карта",
            search_products: "Поиск товаров",
            search_text: "Поиск",
            select_city: "Выберите филиал",
            send: "Отправить",
            send_code: "Отправить код",
            send_message: "Отправить",
            street: "Улица",
            subtotal: "Промежуточный итог",
            to_cart_text: "Продукт, выбранный для",
            to_check: "Оформить",
            total: "Всего",
            total_cart: "Итог заказа",
            update_pas: "Обновить пароль",
            update_password: "Обновление пароля",
            use_bonuses: "Использовать бонусы",
            user_data: "Данные пользователя",
            view_cart: "Посмотреть корзину",
            will_credited: "Будет начислено",
            wishlist: "Избранное",
            wishlist_title: "Пока избранных товаров нет",
            withdrawal_bonuses: "Снятие бонусов",
            you_saved: "Вы сэкономили",
            сancellations: "Для отмены заказа просим обратиться в службу поддержки",
            your_review: "Ваш отзыв о товаре",
            rate_product: "Оцените покупку",
            comments: "Комментарий",
            add_images: "Добавьте фото",
            add_rating: "Поставьте оценку",
            history: "История",
            thanks: "Хорошо",
            current_level:"Текущий уровень",
            your_cashback: "Ваш кэшбек",
            upgrade_level: "Повысьте кэшбек до",
            order_more: "Закажите ещё на",
            your_discount: "Ваша скидка",
            upgrade_discount: "Повысьте скидку до",
            pages: "Страницы",
            privacy_police: "Политика конфиденциальности",
            public_offer: "Публичная оферта",
            footer_text: "Интернет магазин - это удобно!",
            footer_address: "Наш магазин находится по адресу г.Алматы, Толе би 71, ТЦ Silk Way City (1-этаж (1-14)",
            footer_work_time: "10:00 до 22:00 (ежедневно)",
            footer_bottom_text: "© Panama.kz",
            application_banner_title: "ПОПРОБУЙТЕ НАШЕ ПРИЛОЖЕНИЕ",
            application_banner_content: "Всегда на ногах? Перейдите на приложение!",
            application_modal_title: "Скачивайте наше мобильное приложение!",
            application_modal_content: "Будьте первым, кто получит промокоды, скидки и специальные акции!",
            delivery_points: "Пункты самовывоза",
            promo_discount_content: 'Скидка по промокоду',
            delivery_price: 'Цена доставки',
            promo_delivery_content: 'Доставка по промокоду',
            support:"Чат с поддержкой",
            send_file: "Отправить файл",
            comment: "Комментарий"
        }
        this._lang = localStorage.getItem('i18nextLng')
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._minimumBonusAmount = null
        this._registerByPhone = false
        this._city = null
        this._allCities = []
        this._editMode = false
        this._mobView = false
        this._reviewEnabled = false
        this._autoTranslate = false
        makeAutoObservable(this)
    }

    setAutoTranslate(autoTranslate) {
        this._autoTranslate = autoTranslate
    }

    setMobView(mobView) {
        this._mobView = mobView
    }

    setReviewEnabled(reviewEnabled) {
        this._reviewEnabled = reviewEnabled
    }

    setEditMode(editMode) {
        this._editMode = editMode
    }

    setMinimumBonusAmount(minimumBonusAmount) {
        this._minimumBonusAmount = minimumBonusAmount
    }

    setAllCity(cities) {
        this._allCities = cities
    }

    setCity(city) {
        localStorage.setItem('city', JSON.stringify(city));
        this._city = city
    }

    setRegisterByPhone(registerByPhone) {
        this._registerByPhone = registerByPhone
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get autoTranslate() {
        return this._autoTranslate
    }

    get reviewEnabled() {
        return this._reviewEnabled
    }

    get mobView() {
        return this._mobView
    }

    get minimumBonusAmount() {
        return this._minimumBonusAmount
    }

    get editMode() {
        return this._editMode
    }
    
    get city() {
        return JSON.parse(localStorage.getItem('city'));
    }

    get cityString() {
        return this._city
    }

    get allCities() {
        return this._allCities
    }
    
    get registerByPhone() {
        return this._registerByPhone
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}