import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"
import { Context } from '../..';

const BlogCard = ({card}) => {
    const {app} = useContext(Context);

  return (
        <div className="blog-box ratio_50">
            <NavLink to={`/${app.lang}/articles/${card.slug}`} className="blog-box-image">
                <img src={card.image ? card.image : img} className="img-fluid bg-img" alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            </NavLink>

            <div className="blog-detail">
                {card.subtitle && <label>{card.subtitle}</label>}
                <NavLink to={`/${app.lang}/articles/${card.slug}`}>
                    <h2>{card.title}</h2>
                </NavLink>
                <div className="blog-list">
                    <span>{card.date}</span>
                </div>
            </div>
        </div>
  )
}

export default BlogCard