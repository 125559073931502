import React, { useContext } from 'react'
import { Context } from '../..';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"

const ServiceCard = ({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="blog-box wow fadeInUp">
        <div className="blog-image">
            <NavLink to={`/${app.lang}/services/${card.slug}`}>
                <img src={card.image ? card.image : img} className="bg-img" alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            </NavLink>
        </div>

        <div className="blog-contain">
            <div className="blog-label">
                <span className="time"><i data-feather="clock"></i> <span>{card.date}</span></span>
            </div>
            <NavLink to={`/${app.lang}/services/${card.slug}`}>
                <h3>{card.title}</h3>
            </NavLink>
        </div>
    </div>
  )
}

export default ServiceCard