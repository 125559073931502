import React, { useContext, useEffect, useState } from 'react'
import FilterItem from '../simple/FilterItem'
import { Collapse } from 'react-bootstrap'
import { Context } from '../..'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import PriceToddle from '../simple/PriceToddle'
import FilterAttributesService from '../../services/general/filterAttributesService'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import AttributeCollapse from './AttributeCollapse'

const Filter = observer(({setFilterData, openFilter, setOpenFilter, categories, categoryLoading}) => {
    const {slug} = useParams()
    const [open, setOpen] = useState(true);
    const {app} = useContext(Context);
    const [filters, setFilters] = useState([]);
    const navigate = useNavigate();
    let query = new URLSearchParams(useLocation().search);

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    const [filterStates, setFilterStates] = useState([])
    const [filterAttributes, setFilterAttributes] = useState([])

    const {info: filtersItems } = useFetchInfo(FilterAttributesService.index, slug && `slug=${slug}`)

    useEffect(() => {
        if(query.get('categories[]')){
            let arr = []
            for (const [key, value] of query) {
                if(key === "categories[]"){
                    arr.push(value)
                }
            }
            setFilters(arr) 
        }
    }, [query.get('categories[]')])
    
    const handleFilter = (mas=filters, minPrice=minValue, maxPrice=maxValue, states = filterStates, attributes = filterAttributes) => {
        const categoryParams = mas.map(category => `categories[]=${category}`).join('&');
        const priceParams = (minPrice || minPrice === 0) && maxPrice ? `min_price=${minPrice}&max_price=${maxPrice}` : '';
        const stateParams = states.map(state => `state_slugs[]=${state}`).join('&');
        const attributeParams = attributes.map(attr => `attributes[${attr.key}][]=${attr.value}`).join('&');
        
        const str = [categoryParams, priceParams, stateParams, attributeParams].filter(param => param).join('&');

        setFilterData(str);
        navigate({ search: str });
        setOpenFilter(false);
    }

    const handleReset = () => {
        setFilterStates([]);
        setFilters([]);
        setFilterData('')
        navigate({
            search: ''
        });
        setOpenFilter(false)
    }

    const changeStateFilters = (item) => {
        const newFilterStates = filterStates.includes(item)
        ? filterStates.filter(state => state !== item)
        : [...filterStates, item];
    
        setFilterStates(newFilterStates);
        handleFilter(filters, minValue, maxValue, newFilterStates);
    }

  return (
    (filtersItems?.price_max !==  filtersItems?.price_min || categories) &&
    <div className="col-custome-3">
        <div className={openFilter ? "left-box wow fadeInUp show" : "left-box wow fadeInUp"}>
            <div className="shop-left-sidebar">
                <div className="back-button" onClick={() => setOpenFilter(false)}>
                    <h3><i className="fa-solid fa-arrow-left"></i>{app.localizationsItems?.back}</h3>
                </div>
                {filtersItems?.product_states?.length >= 1 &&
                    <div className="filter-category">
                        <div className="filter-title">
                            <h2>{app.localizationsItems?.filter}</h2>
                            <div onClick={handleReset} className='reset-btn'>{app.localizationsItems?.reset}</div>
                        </div>
                        <ul>
                            {filtersItems?.product_states?.map((card, index) => 
                                <li key={index} className={filterStates.find(it => it === card.slug) && 'active'} onClick={() => changeStateFilters(card.slug)}>
                                    <div>{card.name}</div>
                                </li>
                            )}
                        </ul>
                    </div>
                }
                <div className="accordion custome-accordion" id="accordionExample">
                    {categories && <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" aria-expanded={open}
                                aria-controls="collapseOne"
                                onClick={() => setOpen(!open)}
                                >
                                <span>{app.localizationsItems?.categories}</span>
                            </button>
                        </h2>
                        <Collapse in={open} className="accordion-collapse">
                            <div className="accordion-body" id="collapseOne" >
                                <ul className={categoryLoading ? "category-list custom-padding custom-height isLoading" : "category-list custom-padding custom-height"}>
                                    {categories.map((card, index) =>
                                        <li key={index}>
                                            <FilterItem card={card} filters={filters} setFilters={setFilters} handleFilter={handleFilter}/>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </Collapse>
                    </div>}
                    <PriceToddle items={filtersItems} minValue={minValue} setMinValue={setMinValue} maxValue={maxValue} setMaxValue={setMaxValue} filters={filters} handleFilter={handleFilter}/>
                    {filtersItems?.product_traits?.map((card, index) =>
                        <AttributeCollapse card={card} key={index} filters={filterAttributes} setFilters={setFilterAttributes} handleFilter={handleFilter} categoryFilters={filters} minValue={minValue} maxValue={maxValue} states={filterStates}/>
                    )}
                </div>
            </div>
        </div>
        {openFilter && <div className="bg-overlay show" onClick={() => setOpenFilter(false)}></div>}
    </div>
  )
})

export default Filter