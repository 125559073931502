import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form';

const PaymentCollapse = ({card}) => {
    const [show, setShow] = useState(false);

    const {register, formState: {errors}} = useFormContext();

    const openCollapse = (e) => {
        setShow(!show)
    }

  return (
    <div className="accordion-item">
        <div className="accordion-header">
            <div className="accordion-button">
                <div className="custom-form-check form-check mb-0">
                    <label className="form-check-label">
                        <input className="form-check-input mt-0" required value={card.id} type="radio" 
                            {...register('payment_id', {required: true})}
                        />
                        {card.name}
                    </label>
                    <i className={show ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
                        aria-expanded={show}
                        onClick={(e) => openCollapse(e)}
                        aria-controls="example-collapse-text"
                    ></i>
                </div>
            </div>
        </div>
        <div className={show ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'}>
            <div className="accordion-body">
                <p className="cod-review"
                    dangerouslySetInnerHTML={{ __html: card.description}}
                />
            </div>
        </div>
    </div>
  )
}

export default PaymentCollapse