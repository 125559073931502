import {makeAutoObservable} from "mobx";

export default class ProductCategoriesStore {
    constructor() {
        this._categories = []
        this._pages = []
        makeAutoObservable(this)
    }

    setCategories(categories) {
        this._categories = categories
    }

    setPages(pages) {
        this._pages = pages
    }

    get categories() {
        return this._categories
    }

    get pages() {
        return this._pages
    }

}