import React from 'react'
import OurBusinessCard from '../simple/OurBusinessCard'
import Slider from "react-slick";

const OurBusinessSlider = ({elements}) => {
    const settings = {
		infinite: false,
		slidesToScroll: 1,
		slidesToShow: 3,
		arrows: false,
		responsive: [{
				breakpoint: 1262,
				settings: {
					slidesToShow: 2,
					autoplay: true,
					autoplaySpeed: 3500,
					dots: true,
					autoplay: true,
					autoplaySpeed: 2800,
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1,
					dots: true,
					autoplay: true,
					autoplaySpeed: 2800,
				}
			},
		]
    }

  return (
    <Slider className="slider-3_1 product-wrapper" {...settings}>
        {elements?.map((card, index) =>
            <OurBusinessCard card={card} key={index}/>
        )}
    </Slider>
  )
}

export default OurBusinessSlider