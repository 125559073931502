import React from 'react'
import Slider from 'react-slick'
import ExcellenceBox from '../simple/ExcellenceBox'

const Excellences = ({elem}) => {
    const settings = {
        arrows: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    dots: true,
                }
            },
            {
                breakpoint: 474,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    dots: true,
                }
            },
        ]
    }
  return (
	<section className="faq-contain">
		<div className="container">
			<div className="row">
				<div className="col-12">
                    {elem?.elements && 
                        <Slider className="slider-4-2 product-wrapper" {...settings}>
                            {elem?.elements?.map((card, index) =>
                                <ExcellenceBox card={card} key={index}/>
                            )}
                        </Slider>
                    }
				</div>
			</div>
		</div>
	</section>
  )
}

export default Excellences