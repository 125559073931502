import React from 'react'
import Slider from "react-slick";
import { useState } from 'react'
import Fancybox from './FancyBox'
import img from "../../images/placeholder.png"

const ProductSlider = ({images, image}) => {
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: slider1,
    }

    const settings2 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: slider2,
        dots: false,
        focusOnSelect: true,
        infinite: false,
        arrows: true,
        responsive: [{
                breakpoint: 1400,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    vertical: false,
                }
            }, {
                breakpoint: 430,
                settings: {
                    slidesToShow: 3,
                    vertical: false,
                }
            },
        ]
    }

  return (
    <div className="product-left-box">
        <div className="row g-sm-4 g-2">
            <div className="col-12">
                <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                    {images ?
                        <Slider className="product-main no-arrow" {...settings} ref={(slider) => setSlider2(slider)}>
                            {images.map((image, index) =>
                                <div className="slider-image" key={index}>
                                        <a href={image} data-width="700" data-height="700"  target="_blank" data-fancybox="gallery">
                                            <img src={image ? image : img} id="img-1" data-zoom-image={image} className="img-fluid image_zoom_cls-0" alt=""
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                            />
                                        </a>
                                </div>
                            )}
                        </Slider>
                        :
                        <div className="slider-image">
                                <a href={image} data-width="700" data-height="700"  target="_blank" data-fancybox="gallery">
                                    <img src={image ? image : img} id="img-1" data-zoom-image={image} className="img-fluid image_zoom_cls-0" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
                                </a>
                        </div>
                    }
                </Fancybox>
            </div>

            {images && <div className="col-12 mt-1">
                <Slider className="left-slider-image left-slider slick-top" {...settings2} ref={(slider) => setSlider1(slider)}>
                    {images.map((image, index) =>
                        <div className="sidebar-image" key={index}>
                            <img src={image ? image : img} className="img-fluid" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                        </div>
                    )}
                </Slider>
            </div>}
        </div>
    </div>
  )
}

export default ProductSlider
