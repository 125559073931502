import React, { useContext, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import UserServices from '../../services/general/userServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'

const DeliveryPoints = observer(() => {
    const {app} = useContext(Context);

    const {register, formState: {errors}} = useFormContext();

    const {
        items,
        fetchItems
    } = useFetchItems(UserServices.cart.order.pickupAddresses)

  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-location-dot'/>
        </div>
        <div className="checkout-box">
            <div className="checkout-title">
                <h4>{app.localizationsItems?.delivery_points}</h4>
            </div>

            <div className="checkout-detail">
                <div className="accordion accordion-flush custom-accordion">
                    {items?.items?.map((card, index) =>
                        <div className="accordion-item" key={index}>
                            <div className="accordion-header">
                                <div className="accordion-button">
                                    <div className="custom-form-check form-check mb-0">
                                        <label className="form-check-label">
                                            <input className="form-check-input mt-0" required value={card.id} type="radio" 
                                                {...register('address_id', {required: true})}
                                            />
                                            {card.title}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
  )
})

export default DeliveryPoints