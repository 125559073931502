import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import img from "../../images/placeholder.png"
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const BlogCard2 = observer(({card}) => {
    const navigate = useNavigate();

    const {app} = useContext(Context);

  return (
    <div className="blog-box wow fadeInUp">
        <div className="blog-image">
            <NavLink to={`/${app.lang}/articles/${card.slug}`}>
                <img src={card.image ? card.image : img} className="bg-img" alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            </NavLink>
        </div>

        <div className="blog-contain">
            <div className="blog-label">
                <span className="time"><i data-feather="clock"></i> <span>{card.date}</span></span>
            </div>
            <NavLink to={`/${app.lang}/articles/${card.slug}`}>
                <h3>{card.title}</h3>
            </NavLink>
            <button className="blog-button" onClick={() => navigate(`/${app.lang}/articles/${card.slug}`)}>
                {app.localizationsItems?.learn_more}
                <i className="fa-solid fa-right-long"></i>
            </button>
        </div>
    </div>
  )
})

export default BlogCard2