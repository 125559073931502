import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const SelectCityModal = observer(({show, setShow, setCity}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {handleSubmit, register, setValue} = useForm();

    useEffect(() => {
        if(localStorage.getItem('city')){
            setValue('city', localStorage.getItem('city'))
        }
    }, [])

    const CustomSubmit = (data) => {
        const selectedCity = JSON.parse(data.city);
        app.setCity(selectedCity)
        setCity(selectedCity)
        setShow(false)
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form className='form-modal form-check' onSubmit={handleSubmit(CustomSubmit)}>
                <h2 className='mb-4'>{app.localizationsItems?.select_city}</h2>
                <div className="row">
                    {app.allCities?.map((card, index) =>
                        <div className="col-6" key={index}>
                            <label className="form-check-label">
                                <input {...register('city')} value={JSON.stringify({ value: card.value, label: card.label, location: card.location })} className="form-check-input mt-0" type="radio"/>
                                {card.label}
                            </label>
                        </div>
                    )}
                </div>
                <div className='row justify-content-end'>
                    <div className="col-auto">
                        <div className='btn btn-light shopping-button text-dark' onClick={() => setShow(false)}>{app.localizationsItems?.cancel}</div>
                    </div>
                    <div className="col-auto">
                        <button className='btn btn-animation fw-bold ms-auto'>{app.localizationsItems?.save}</button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default SelectCityModal