import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import QuickProductViewModal from '../modals/QuickProductViewModal';
import { useState } from 'react';
import img from "../../images/placeholder.png"
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const OfferCard = observer(({card}) => {
    const {app} = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
        <div className="offer-card">
            <div className='offer-card-label'>
                {card.title}
            </div>
            <NavLink to={`/${app.lang}/products/${card.slug}`}>
                <img src={card.image} alt="" 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            </NavLink>
            <button className="buy-box btn theme-bg-color text-white btn-cart" onClick={() => setModalOpen(true)}>
                <i className="iconly-Buy icli m-0"></i>
            </button>
        </div>
        {modalOpen && <QuickProductViewModal show={modalOpen} setShow={setModalOpen} slug={card.slug}/>}
    </div>
  )
})

export default OfferCard