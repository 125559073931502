import React, { useState } from 'react'
import ReviewModal from '../modals/ReviewModal'

const LeaveFeedback = ({id}) => {
    const [show, setShow] = useState();

  return (
    <div className="ms-auto">
        <button className='btn btn-animation btn-md fw-bold' onClick={() => setShow(true)}>Оставить отзыв</button>
        {show &&
            <ReviewModal
                show={show}
                setShow={setShow}
                id={id}
            />
        }
    </div>
  )
}

export default LeaveFeedback