import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const MenuItem = observer(({it, handleClose}) => {
    const {app} = useContext(Context);
    

  return (
    <li className="nav-item dropdown">
        <NavLink to={`/${app.lang}/page/${it.slug}`} className="nav-link dropdown-toggle nav-none" onClick={handleClose}>{it.title}</NavLink>
    </li>
  )
})

export default MenuItem