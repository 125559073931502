import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const SearchSection = observer(({setFilterData}) => {
    const [term, setTerm] = useState('');
    let query = new URLSearchParams(useLocation().search);
    const {app} = useContext(Context);
    const navigate = useNavigate()

    const searchClick = () => {
        setFilterData(`?term=${term}`)
        navigate({
            search: query.toString()
        });
    }

    useEffect(() => {
        if(query.get('term')){
            setTerm(query.get('term'))
        }
    }, [])

  return (
    <section className="search-section">
        <div className="container-fluid-lg">
            <div className="row">
                <div className="col-xxl-6 col-xl-8 mx-auto">
                    <div className="title d-block text-center">
                        <h2>{app.localizationsItems?.search_products}</h2>
                        <span className="title-leaf"></span>
                    </div>

                    <form className="search-box">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder={app.localizationsItems?.search_products} value={term} onChange={(e) => setTerm(e.target.value)} />
                            <button className="btn theme-bg-color text-white m-0" type="button" onClick={searchClick}>{app.localizationsItems?.search_text}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
  )
})

export default SearchSection