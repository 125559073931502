import React, { useContext, useState } from 'react'
import RecentBox from '../simple/RecentBox'
import ArticleServices from '../../services/general/articleServices'
import useFetchItems from '../../hooks/useFetchItems'
import { Collapse } from 'react-bootstrap'
import { Context } from '../..'

const BlogSidebar = () => {
    const {app} = useContext(Context);
    const [open, setOpen] = useState(true);

    const {
        items,
        loading,
    } = useFetchItems(ArticleServices.index)

  return (
    <div className="left-sidebar-box">
        <div className="accordion left-accordion-box mt-0" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button className="accordion-button" type="button" aria-expanded={open}
                        aria-controls="panelsStayOpen-collapseOne"
                        onClick={() => setOpen(!open)}
                        >
                        {app.localizationsItems?.recent_posts}
                    </button>
                </h2>
                <Collapse in={open} className="accordion-collapse">
                    <div className="accordion-body pt-0" id="panelsStayOpen-collapseOne">
                        <div className="recent-post-box">
                            {items?.items.slice(0,4).map((elem, index) => 
                                <RecentBox key={index} elem={elem}/>
                            )}
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    </div>
  )
}

export default BlogSidebar