import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import img1 from "../../../images/inner-page/sign-up.png"
import AuthServices from '../../../services/general/authServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import PhoneCheckModal from '../../../components/modals/PhoneCheckModal';

const RegistrationContainer = observer(() => {
	const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [phoneCheckModal, setPhoneCheckModal] = useState();
    const [phone, setPhone] = useState();

    const {app} = useContext(Context);
    const [showPassword, setShowPassword] = useState();

	const {handleSubmit, register, control, formState: {errors}} = useForm();

	const CustomSubmit = async (data) => {
        setLoading(true);

        const res = await AuthServices.register(data);

        if (res.statusCode === 200) {
            if(app.registerByPhone){
                setPhoneCheckModal(true);
                setPhone(data.phone)
            }else{
                navigate(`/${app.lang}`);
                toast.success(res.message);
            }
        }else{
			toast.error(res.message);
		}
        setLoading(false);
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
  return (
	<section className="log-in-section section-b-space">
		<div className="container-fluid-lg w-100">
			<div className="row">
				<div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
					<div className="image-contain">
						<img src={img1} className="img-fluid" alt=""/>
					</div>
				</div>

				<div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
					<div className="log-in-box">
						<div className="log-in-title">
							<h3>{app.localizationsItems?.regis_title}</h3>
							<h4>{app.localizationsItems?.regis_content}</h4>
						</div>

						<div className="input-box">
							<form className={loading ? "row g-4 isLoading" : 'row g-4'} onSubmit={handleSubmit(CustomSubmit)}>
								{app.registerByPhone ?
                                    <div className="col-12">
                                        <div className="form-floating theme-form-floating">
                                        <Controller defaultValue="" name="phone" control={control}
                                            render={({field: {onChange, value}}) => (
                                            <ReactInputMask onChange={onChange} value={value}
                                                                mask="+7(999)999-99-99" maskChar="_">
                                                                {
                                                                    inputProps => (
                                                                        <input type="tel" className={errors?.phone ? "form-control error" : "form-control"} placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                    )
                                                                }
                                            </ReactInputMask>
                                                    )}
                                            rules={{pattern: {
                                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                            }, required: true}}
                                        />
                                        <label>{app.localizationsItems?.phone}*</label>
                                        </div>
                                    </div>
                                :
                                    <>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input {...register('name')} required type="text" className="form-control" placeholder={app.localizationsItems?.fio}/>
                                                <label>{app.localizationsItems?.fio}</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input {...register('email')} required type="email" className="form-control" placeholder={app.localizationsItems?.email}/>
                                                <label>{app.localizationsItems?.email}</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <Controller defaultValue="" name="phone" control={control}
                                                    render={({field: {onChange, value}}) => (
                                                    <ReactInputMask onChange={onChange} value={value}
                                                                        mask="+7(999)999-99-99" maskChar="_">
                                                                        {
                                                                            inputProps => (
                                                                                <input type="tel" className={errors?.phone ? "form-control error" : "form-control"} placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                            )
                                                                        }
                                                    </ReactInputMask>
                                                            )}
                                                    rules={{pattern: {
                                                        value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                                    }, required: true}}
                                                />
                                                <label>{app.localizationsItems?.phone}*</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input {...register('password')} required type={showPassword ? "text" : "password"} className="form-control" placeholder={app.localizationsItems?.password}/>
                                                <label>{app.localizationsItems?.password}</label>
                                                <span className='password-eye' onClick={togglePasswordVisibility}><i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i></span>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating">
                                                <input {...register('password_confirmation')} required type="password" className="form-control" placeholder={app.localizationsItems?.confirm_password}/>
                                                <label>{app.localizationsItems?.confirm_password}</label>
                                            </div>
                                        </div>
                                    </>
                                }

								<div className="col-12">
									<button className="btn btn-animation w-100" type="submit">{app.localizationsItems?.register}</button>
								</div>
							</form>
						</div>

						<div className="other-log-in">
							<h6></h6>
						</div>

						<div className="sign-up-box">
							<h4>{app.localizationsItems?.have_account}</h4>
							<NavLink to={`/${app.lang}/login`}>{app.localizationsItems?.login}</NavLink>
						</div>
					</div>
				</div>

				<div className="col-xxl-7 col-xl-6 col-lg-6"></div>
			</div>
		</div>
        {phoneCheckModal && <PhoneCheckModal show={phoneCheckModal} setShow={setPhoneCheckModal} phone={phone}/>}
	</section>
  )
})

export default RegistrationContainer