import React, { useEffect, useState } from 'react'

const ToTop = ({widgets}) => {
    const [visible, setVisible] = useState();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
    };
      
    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    visible &&
	<div className={widgets ? "theme-option theme-option-widgets" : "theme-option"} onClick={scrollToTop}>
		<div className="back-to-top">
			<div className="back-to-top">
				<i className="fas fa-chevron-up"></i>
			</div>
		</div>
	</div>
  )
}

export default ToTop