import React from 'react'
import Slider from "react-slick";
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import BestsellerCard from '../simple/BestsellerCard';

const ProductBestsellers = ({item}) => {
    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    fade: true,
                }
            },
        ]
    }

    const { 
        items,
    } = useFetchItems(CatalogServices.products.index, null, `type=${item.products_type}&`);

  return (
	<section className="deal-section section-b-space">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{item?.title}</h2>
			</div>
			<div className="row">
				<div className="col-12">
                {items?.items && 
					<Slider className="three-slider-1 arrow-slider"  {...settings}>
                        {items.items.map((card, index) =>
                            <BestsellerCard card={card} key={index}/>
                        )}
					</Slider>
                }
				</div>
			</div>
		</div>
	</section>
  )
}

export default ProductBestsellers