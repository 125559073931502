import React, { useContext, useState } from 'react'
import RecentBox from '../simple/RecentBox'
import ArticleServices from '../../services/general/articleServices'
import useFetchItems from '../../hooks/useFetchItems'
import { Context } from '../..'

const EventSidebar = () => {
    const {app} = useContext(Context);
    const [open, setOpen] = useState(true);

    const {
        items,
        loading,
    } = useFetchItems(ArticleServices.index)
      
  return (
    <div className="left-sidebar-box">
        <div className="accordion left-accordion-box mt-0">
            <div className="accordion-item">
                <h3 className='title-sidebar'>
                    {app.localizationsItems?.recent_events}
                </h3>
                <div className="accordion-collapse">
                    <div className="accordion-body pt-0" id="panelsStayOpen-collapseOne">
                        <div className="recent-post-box">
                            {items?.items.slice(0,4).map((elem, index) => 
                                <RecentBox key={index} elem={elem} link={'events'}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EventSidebar