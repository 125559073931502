import React from 'react'
import BlogCard2 from '../simple/BlogCard2'
import BlogSidebar from './BlogSidebar'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import Pagination from './Pagination'

const BlogContainer = () => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

  return (
	<section className="blog-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row g-4">
				<div className="col-xxl-9 col-xl-8 col-lg-7 order-lg-2">
					<div className="row g-4 ratio_65">
                        {items?.items?.map((card, index) =>
                            <div className="col-xxl-4 col-sm-6" key={index}>
                                <BlogCard2 card={card}/>
                            </div>
                        )}
					</div>
                    <div className='custome-pagination'>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </div>
				</div>

				<div className="col-xxl-3 col-xl-4 col-lg-5 d-lg-block d-none">
                    <BlogSidebar/>
				</div>
			</div>
		</div>
	</section>
  )
}

export default BlogContainer