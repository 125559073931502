import React from 'react'
import { useState } from 'react'
import ToCartModal from '../modals/ToCartModal';

const PriceCountButton = ({card}) => {
    const [show, setShow] = useState(false);

  return (
    <div className="price-qty">
        <button className="buy-button buy-button-2 btn btn-cart" onClick={() => setShow(true)}>
            <i className="iconly-Buy icli text-white m-0"></i>
        </button>

        {show && <ToCartModal show={show} setShow={setShow} slug={card?.slug}/>}
    </div>
  )
}

export default PriceCountButton