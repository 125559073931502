import React, { useContext} from 'react'
import useFetchItems from '../../../hooks/useFetchItems';
import UserServices from '../../../services/general/userServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

const Bonuses = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
    } = useFetchItems(UserServices.profile.balanceHistory)

  return (
    <div className={loading ? "dashboard-wishlist isLoading height-100" : "dashboard-wishlist"}>
        <div className="title">
            <h2>{app.localizationsItems?.bonuses}</h2>
                <span className="title-leaf title-leaf-gray">
            </span>
        </div>
        {items?.items?.length >= 1 ?
            <div className='order-tab dashboard-bg-box'>
                <div className="table-responsive">
                    <table className='table order-table'>
                        <thead>
                            <tr>
                                <th>{app.localizationsItems?.order_number}</th>
                                <th>{app.localizationsItems?.order_date}</th>
                                <th>{app.localizationsItems?.order_event}</th>
                                <th>{app.localizationsItems?.bonuses}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items?.items?.map((card, index) =>
                                <tr key={index}>
                                    <td>
                                        <NavLink to={`/${app.lang}/user/orders/${card.order_id}`}>
                                            №{card.order_id}
                                        </NavLink>
                                    </td>
                                    <td>{card.created_at}</td>
                                    <td>
                                        <span>{card.transaction}</span>
                                    </td>
                                    <td>
                                        <label className={card.value > 0 ? 'success' : 'danger'}>{card.value} Б</label>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div>
                <h4>{app.localizationsItems?.empty_data}</h4>
            </div>
        }
    </div>
  )
})

export default Bonuses