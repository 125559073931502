import React, { useContext } from 'react'
import useFetchItems from '../../../hooks/useFetchItems'
import UserServices from '../../../services/general/userServices'
import OrderBox from './OrderBox'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import Pagination from '../../../components/complex/Pagination'

const Order = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick
    } = useFetchItems(UserServices.orders.index)

  return (       
    <div className={loading ? "dashboard-order isLoading height-100" : "dashboard-order"}>
        <div className="title">
            <h2>{app.localizationsItems?.my_orders}</h2>
            <span className="title-leaf title-leaf-gray"></span>
        </div>
        <div className="dashboard-user-name">
            <p className="text-content">{app.localizationsItems?.сancellations}</p>
        </div>
        {items?.items?.length >= 1 ?
            <div className="order-contain">
                {items.items.map((card, index) =>
                    <OrderBox card={card} key={index}/>
                )}
                <div className='custome-pagination'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
            :
            <div>
                <h4>{app.localizationsItems?.empty_data}</h4>
            </div>
        }
    </div>
  )
})

export default Order