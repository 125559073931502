import React, { useContext, useState } from 'react'
import AddressBox from './AddressBox'
import useFetchItems from '../../hooks/useFetchItems'
import UserServices from '../../services/general/userServices'
import { Context } from '../..'
import AddAddressModal from '../../pages/user/component/AddAddressModal'
import { observer } from 'mobx-react-lite'

const DeliveryAddress = observer(({setAddress, deliveryZones}) => {
    const {app} = useContext(Context);
    
    const [modalOpen, setModalOpen] = useState(false);

    const handleShow = () => setModalOpen(true);

    const {
        items,
        fetchItems
    } = useFetchItems(UserServices.addresses.index)

  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-location-dot'/>
        </div>
        <div className="checkout-box">
            <div className="checkout-title">
                <h4>{app.localizationsItems?.delivery_address}</h4>
                <button className='btn btn-animation btn-sm' onClick={handleShow}>{app.localizationsItems?.add_address}</button>
            </div>

            <div className="checkout-detail">
                <div className="row g-4">
                    {items?.items?.map((card, index) =>
                        <div className="col-xxl-6 col-lg-12 col-md-6" key={index}>
                            <AddressBox card={card} fetchItems={fetchItems} setAddress={setAddress} deliveryZones={deliveryZones}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
        {modalOpen && <AddAddressModal show={modalOpen} setShow={setModalOpen} fetchItems={fetchItems} deliveryZones={deliveryZones}/>}
    </>
  )
})

export default DeliveryAddress