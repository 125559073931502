import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Menu = observer(({item}) => {
    const {app} = useContext(Context);
    
  return (
    <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
        <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
            <div className="offcanvas-header navbar-shadow">
                <h5></h5>
                <button className="btn-close lead" type="button" ></button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav">
                    {item?.map((it, index) =>
                        it.slug === "articles" ?
                            <li className="nav-item dropdown new-nav-item" key={index}>
                                    <NavLink 
                                        className={it.items ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle nav-none"} to={`/${app.lang}/${it.slug}`} 
                                        data-bs-toggle="dropdown"
                                    >
                                        {it.title}
                                    </NavLink>
                                </li>
                            :
                            it.slug !== "main" &&
                                <li className="nav-item dropdown new-nav-item" key={index}>
                                    <NavLink 
                                        className={it.items ? "nav-link dropdown-toggle" : "nav-link dropdown-toggle nav-none"} to={`/${app.lang}/page/${it.slug}`} 
                                        data-bs-toggle="dropdown"
                                    >
                                        {it.title}
                                    </NavLink>
                                </li>
                     )}
                </ul>
            </div>
        </div>
    </div>
  )
})

export default Menu