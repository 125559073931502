import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useState } from 'react';
import Utils from '../../services/utils';
import useWindowResize from '../../hooks/useWindowResize.hook';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import appStore from "../../images/appstore.png"
import playMarket from "../../images/googleplay.png"

const PopupModal = observer(({
    show, 
    setShow,
    appStoreLink,
    googlePlayLink,
    setVisible
}) => {
    const {app} = useContext(Context);

    const handleClose = () => {
        setShow(false)
        Utils.setCookie('popup-modal', 'true', {secure: true, 'max-age': 86400});
        setVisible(false)
    }


  return (
    <Modal show={show} onHide={handleClose} centered className='popup-modal-content'>
        <div className='close' onClick={handleClose}>
            <i className='fa fa-close'></i>
        </div>
        <Modal.Body>
            <div className="popup-modal">
                <div>
                    <h3>{app.localizationsItems?.application_modal_title}</h3>
                    <p className='subtitle'>{app.localizationsItems?.application_modal_content}</p>
                    <div className="description-flex">
                        {appStoreLink && <a href={appStoreLink} target="_blank" rel="noopener">
                            <img src={appStore} alt=""/>
                        </a>}
                        {googlePlayLink && <a href={googlePlayLink} target="_blank" rel="noopener">
                            <img src={playMarket} alt=""/>
                        </a>}
                    </div>
                    <button className='btn theme-bg-color mt-2 btn-md text-white fw-bold' onClick={handleClose}>{app.localizationsItems?.thanks}</button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default PopupModal