import React, { useRef, useState } from 'react'
import ProductDropdownModal from '../modals/ProductDropdownModal';

const DropdownProductPrice = ({description}) => {
    const [show, setShow] = useState();

  return (
    <div className='product-droupdown'>
        <i className='fa fa-info-circle' onClick={(e) => setShow(true)}></i>
        {show && <ProductDropdownModal show={show} setShow={setShow} description={description}/>}
    </div>
  )
}

export default DropdownProductPrice