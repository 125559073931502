import React, { useContext, useEffect, useState } from 'react'
import ProductReviewCard from '../simple/ProductReviewCard'
import LeaveFeedback from '../simple/LeaveFeedback'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const ProductReviews = observer(({info}) => {
    const {app} = useContext(Context);

    const [reviews, setReviews] = useState();

    useEffect(() => {
        if(info?.reviews){
            setReviews(info.reviews)
        }
    }, [info])

  return (
    <div className="review-box">
        <div className="row g-4">
            <div className="col-12">
                <div className='d-flex mb-3'>
                    <div className="review-title">
                        <h3 className="fw-500">{app.localizationsItems?.clients_reviews}</h3>
                    </div>
                    <LeaveFeedback id={info.id}/>
                </div>

                {reviews?.length >= 1 ?
                    <div className="review-people">
                        <ul className="review-list">
                            {reviews?.map((card, index) =>
                                <ProductReviewCard card={card} key={index}/>
                            )}
                        </ul>
                    </div>
                    :
                    <h2 className='text-center'>{app.localizationsItems?.no_reviews}</h2>
                }
            </div>
        </div>
    </div>
  )
})

export default ProductReviews