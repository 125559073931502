import React from 'react'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ProductTop = observer(({item}) => {
    const {app} = useContext(Context);

  return (
	<section className="breadscrumb-section pt-0 mb-4">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
                    <div className="breadscrumb-contain">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <NavLink to={`/${app.lang}`}>
                                    <i className="fa-solid fa-house"></i>
                                </NavLink>
                            </li>
                            <li className="breadcrumb-item">
                                <NavLink to={`/${app.lang}/catalog`}>
                                    {app.localizationsItems?.catalog}
                                </NavLink>
                            </li>
                            {item?.breadcrumps?.map((card, index) =>
                                <li className="breadcrumb-item active" aria-current="page" key={index}>
                                    <NavLink to={`/${app.lang}/catalog/${card.slug}`}>
                                        {card.title}
                                    </NavLink>
                                </li>
                            )}
                            <li className="breadcrumb-item active" aria-current="page">{item?.title}</li>
                        </ol>
                    </div>
				</div>
                <div className="col-12">
                    <h2>{item?.title}</h2>
                </div>
			</div>
		</div>
	</section>
  )
})

export default ProductTop