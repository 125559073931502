import React from 'react'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react';
import { Context } from '../..';

const PageTop = ({item}) => {
    const {app} = useContext(Context);

  return (
	<section className="breadscrumb-section pt-0">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
					<div className="breadscrumb-contain">
						<h2>{item?.title}</h2>
						<nav>
							<ol className="breadcrumb mb-0">
								<li className="breadcrumb-item">
									<NavLink to={`/${app.lang}`}>
										<i className="fa-solid fa-house"></i>
									</NavLink>
								</li>
								<li className="breadcrumb-item active" aria-current="page">{item?.title}</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default PageTop