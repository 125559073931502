import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { Context } from '../..';
import img from "../../images/success.png"
import AppServices from '../../services/general/appServices';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

const HelpModal = observer(({
    show,
    setShow
}) => {
    const {app, user} = useContext(Context);

    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState();

    const [visible, setVisible] = useState();

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const customSubmit = async (data) => {
        setLoading(true);

        const res = await AppServices.request(data);

        if(res.statusCode === 200){
            setLoading(false);
            setVisible(true);
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }else{
            toast.error(res.message);
            setLoading(false);
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='admin-modal'>
        <div className='admin-modal-top'>
            <h2>{visible ? 'Сообщение отправлено' : 'Напишите нам'}</h2>
            <div className='close' onClick={handleClose}>
                <i className='icon-close'></i>
            </div>
        </div>
        <Modal.Body>
            {visible &&
                <div className='admin-modal-text'>
                    <img src={img} alt="" />
                    <p>{app.localizationsItems?.modal_title}</p>
                </div>
            }
            {loading &&
                <div className='isLoading'></div>
            }
                <form className='form-wrapper' onSubmit={handleSubmit(customSubmit)}>
                    <p>{app.localizationsItems?.modal_description}</p>
                    <input {...register('name')} type="text" className='admin-input' placeholder={app.localizationsItems?.name}/>
                    <Controller defaultValue="" name="phone" control={control}
                        render={({field: {onChange, value}}) => (
                            <ReactInputMask onChange={onChange} value={value}
                                mask="+7(999)999-99-99" maskChar="_">
                                {
                                    inputProps => (
                                        <input type="tel" className="admin-input" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                    )
                                }
                            </ReactInputMask>
                        )}
                        rules={{pattern: {
                            value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                        }}}
                    />
                    <textarea {...register('message')} type="text" className='admin-input textarea' placeholder={app.localizationsItems?.your_question}/>
                    <button className='admin-btn'>{app.localizationsItems?.send}</button>
                </form>
        </Modal.Body>
    </Modal>
  )
})

export default HelpModal