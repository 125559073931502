import React, { useState, useEffect } from 'react'
import DeliveryBox from '../simple/DeliveryBox'
import img from "../../images/placeholder.png"

const AboutUs = ({elem}) => {
    const [banner, setBanner] = useState();

    useEffect(() => {
        if (elem?.elements) {
            setBanner(elem.elements[0]);
        }
    }, [elem]);

  return (
    banner &&
	<section className="fresh-vegetable-section section-lg-space">
		<div className="container-fluid-lg">
			<div className="row gx-xl-5 gy-xl-0 g-3 ratio_148_1">
				<div className="col-xl-6 col-12">
					<div className="row g-sm-4 g-2">
						<div className="col-sm-12 col-md-6">
							<div className="fresh-image-2">
								<div>
									<img src={banner.image ? banner.image : img} className="bg-img" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
								</div>
							</div>
						</div>

						<div className="col-sm-12 col-md-6">
							<div className="fresh-image">
								<div>
									<img src={banner.detail_image ? banner.detail_image : img} className="bg-img" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                    />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-xl-6 col-12">
					<div className="fresh-contain p-center-left">
						<div>
							<div className="review-title">
								<h4 dangerouslySetInnerHTML={{__html: banner.subtitle}}></h4>
								<h2 dangerouslySetInnerHTML={{__html: banner.title}}></h2>
							</div>

							<div className="delivery-list">
								<div className="text-content"
                                    dangerouslySetInnerHTML={{ __html: banner.description }}
                                ></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default AboutUs