import React, { useContext, useEffect, useRef, useState } from 'react'
import ProductCard from '../simple/ProductCard'
import CatalogServices from '../../services/general/catalogServices';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const DynaminPaginationPage = observer(({slug}) => {
    const {user} = useContext(Context);

    const observerRef = useRef(null);
    const lastElement = useRef(null);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchProducts = async () => {
        if (loading) return;

        setLoading(true);

        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';
        let str2 = (slug !== 'all' && slug) ? `categories[]=${slug}` : '';

        const res = await CatalogServices.products.index(`page=${currentPage}&${str}${str2}`);

        if(res.statusCode === 200){
            res.content.items && setItems(prevItems => [...prevItems, ...res.content.items]);
            setTotalPages(res.content.total_pages || 1)
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchProducts()
    }, [currentPage])

    useEffect(() => {
        if(loading) return;
        if(observerRef.current) observerRef.current.disconnect();
        var callback = function(entries, observer){
            if(entries[0].isIntersecting && currentPage < totalPages){
                setCurrentPage(currentPage + 1)
            }
        };
        observerRef.current = new IntersectionObserver(callback);

        if (lastElement.current) {
            observerRef.current.observe(lastElement.current);
        }
    }, [loading])
 

  return (
    <div className="tab-content mt-4">
        <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="all-tab">
            <div className="row g-8">
                {items?.map((card, index) =>
                    <div className="col-xxl-2 col-lg-3 col-md-4 col-6 wow fadeInUp" key={index}>
                        <ProductCard card={card} fetchItems={fetchProducts}/>
                    </div>
                )}
                {loading && <div className='isLoading'></div>}
            </div>
        </div>
        <div ref={lastElement}></div>
    </div>
  )
})

export default DynaminPaginationPage