import React, { useContext, useState } from 'react'
import BasketProductTr from './BasketProductTr'
import SummaryBox from './SummaryBox'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import ClearBasketModal from '../modals/ClearBasketModal';

const BasketContainer = observer(() => {
    const [loading, setLoading] = useState();
    const [deleteModal, setDeleteModal] = useState();

    const {app, user} = useContext(Context);

  return (
	<section className="cart-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row g-sm-5 g-3">
				<div className="col-xxl-9">
					<div className="cart-table">
                        {user.cartProducts?.length >= 1 ?
                            <div className="table-responsive-xl">
                                <table className={loading ? "table isLoading" : "table"}>
                                    <tbody>
                                        {user.cartProducts.map((card, index) =>
                                            <BasketProductTr card={card} key={index} setLoading={setLoading}/>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <div className='text-center'>
                                <h3>{app.localizationsItems?.basket_title}</h3>
                            </div>
                        }
					</div>
                    <div className='text-danger text-end mt-3' onClick={() => setDeleteModal(true)}>
                        <i className='fa fa-trash me-1'></i>{app.localizationsItems?.clear_basket}
                    </div>
				</div>

				<div className="col-xxl-3">
                    <SummaryBox products={user.cartProducts}/>
				</div>
			</div>
		</div>
        {deleteModal && <ClearBasketModal show={deleteModal} setShow={setDeleteModal}/>}
	</section>
  )
})

export default BasketContainer