import React, { useContext, useState } from 'react'
import AuthServices from '../../services/general/authServices';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

const UpdatePasswordModal = observer(({show, setShow, email, phone, code}) => {
    const [loading, setLoading] = useState();
    const navigate = useNavigate();

    const {app} = useContext(Context);

    const {handleSubmit, register} = useForm();
    const [showPassword, setShowPassword] = useState();

    const handleClose = () => setShow(false);

    const onSubmit = async (data) => {
        setLoading(true)
        const newData = {
            code: code,
            ...data
        }

        if(email){
            newData.email = email
        }

        if(phone){
            newData.phone = phone
        }

        const res = await AuthServices.updatePassword(newData);

        if (res.statusCode === 200) {
            setShow(false);
            toast.success(res.message)
            navigate(`/${app.lang}/login`)
        }
        setLoading(false)
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
						<h3>{app.localizationsItems?.update_password}</h3>
					</div>
                    <div className="form-floating theme-form-floating log-in-form mb-3">
						<input type={showPassword ? "text" : "password"} {...register('password')} required className="form-control" placeholder={app.localizationsItems?.password}/>
						<label>{app.localizationsItems?.new_password}</label>
                        <span className='password-eye' onClick={togglePasswordVisibility}><i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i></span>
					</div>
                    <div className="form-floating theme-form-floating log-in-form">
						<input type="password" {...register('password_confirmation')} required className="form-control" placeholder={app.localizationsItems?.confirm_password}/>
						<label>{app.localizationsItems?.confirm_password}</label>
					</div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</button>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.update_pas}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default UpdatePasswordModal