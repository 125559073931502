import React, { useContext, useState } from 'react'
import AuthServices from '../../services/general/authServices';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const ResetPasswordModal = observer(({show, setShow, setUpdatePasswordModal, email, phone, setCode}) => {
    const [loading, setLoading] = useState();

    const {app} = useContext(Context);

    const {handleSubmit, register} = useForm();

    const handleClose = () => setShow(false);

    const onSubmit = async (data) => {
        setLoading(true)
        const newData = {
            ...data
        }
        if(email){
            newData.email = email
        }
        if(phone){
            newData.phone = phone
        }

        const res = await AuthServices.checkCode(newData);

        if (res.statusCode === 200) {
            setCode(data.code)
            setShow(false);
            setUpdatePasswordModal(true);
        }
        setLoading(false)
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
						<h3>{app.localizationsItems?.restore_password}</h3>
                        <p>{app.localizationsItems?.restore_password_content}</p>
					</div>
                    <div className="form-floating theme-form-floating log-in-form">
						<input type="text" {...register('code')} required className="form-control" placeholder={app.localizationsItems?.enter_code}/>
						<label>{app.localizationsItems?.enter_code}</label>
					</div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</button>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.rest_pas}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default ResetPasswordModal