import React from 'react'
import Slider from 'react-slick'
import BlogBox from '../simple/BlogBox'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'

const OurBlog = ({elem}) => {
    const settings = {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 5,
        arrows: false,
        responsive: [{
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1215,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 876,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    }

    const {
        items,
        loading,
    } = useFetchItems(ArticleServices.index)

  return (
	<section className="section-lg-space">
		<div className="container-fluid-lg">
			<div className="about-us-title text-center">
				<h4 className="text-content">{elem.subtitle}</h4>
				<h2 className="center">{elem.title}</h2>
			</div>
			<div className="row">
				<div className="col-12">
                    <Slider className="slider-5 ratio_87" {...settings}>
                        {items?.items?.map((card, index) =>
                            <div key={index}>
                                <BlogBox card={card}/>
                            </div>
                        )}
					</Slider>
				</div>
			</div>
		</div>
	</section>
  )
}

export default OurBlog