import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import AppRouter from './navigation/AppRouter';
import AppServices from './services/general/appServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './i18n/i18n'
import './App.scss';
import Header from './components/complex/Header';
import Footer from './components/complex/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import UserServices from './services/general/userServices';
import ScrollToTop from './hooks/scrollToTop';
import AdminTopHeader from './components/admin/AdminTopHeader';

const App = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);

    const checkAuth = async () => {
        if(user.token){
            const res = await UserServices.profile.index();
            if(res.statusCode === 200){
                
            }else{
                user.removeToken();
            }
        }
    }

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            let settings = res.content;
            app.setSettings(settings);

            let bonusesEnabled = settings.find(it => it.type === "bonuses_enabled")?.value;
            app.setBonuses(bonusesEnabled);

            let autoTranslate = settings.find(it => it.type === "auto-translate")?.value;
            app.setAutoTranslate(autoTranslate);

            let reviewsEnabled = settings.find(it => it.type === "reviews-enabled")?.value;
            app.setReviewEnabled(reviewsEnabled);

            let minimumOrderAmount = settings.find(it => it.type === "minimum-order-amount")?.value;
            app.setMinimumOrderAmount(minimumOrderAmount);

            let bonusesEnabledSum = settings.find(it => it.type === "bonuses_enabled_sum")?.value;
            Array.isArray(bonusesEnabledSum) && app.setMinimumBonusAmount(bonusesEnabledSum[0]);

            let registerByPhone = settings.find(it => it.type === "register-by-phone")?.value;
            registerByPhone && app.setRegisterByPhone(registerByPhone);

            let color = settings.find(it => it.type === "color-primary")?.value;

            color && document.documentElement.style.setProperty('--mainColor', color);
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp)
            let localizations = res2.content.find(it => it.slug === temp)?.description;

            let map2 = app.localizationsItems;
            for (let key in localizations) {
                map2[key] = localizations[key];
            }
            app.setLocalizationsItems(map2)
            
            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
    }, [app.lang])

    const tokenCheck = async(tokenParam) => {
        localStorage.setItem('token', tokenParam);

        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            app.setEditMode(true);
            localStorage.setItem('editMode', 'Режим редактирования')
            user.setToken(tokenParam);
            user.setUser(res.content)
            user.setTokenChange(Math.random().toString(16));
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        const tokenParam = queryParams.get('token');

        if(tokenParam){
            tokenCheck(tokenParam);
        }else{
            checkAuth();
        }
    }, [])

  return (
    <>{loading ?
        <div className="fullpage-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        :
        <BrowserRouter>
            <ScrollToTop />
            {(app.editMode && user.user?.roles?.find(it => it.name === 'admin')) ?
                <>
                    <AdminTopHeader/>
                </>
                :
                <>
                    {user.user?.roles?.find(it => it.name === 'admin') && <AdminTopHeader/>}
                    <Header/>
                    <AppRouter/>
                    <Footer/>
                </>
                
            }
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </BrowserRouter>
    }</>
  )
});

export default App
