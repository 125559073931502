import React, { useContext } from 'react'
import { useState } from 'react'
import AddAddressModal from './AddAddressModal'
import AddressItem from './AddressItem'
import useFetchItems from '../../../hooks/useFetchItems'
import UserServices from '../../../services/general/userServices'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import Pagination from '../../../components/complex/Pagination'

const Address = observer(() => {
    const {app} = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);

    const handleShow = () => setModalOpen(true);

    const {
        items,
        fetchItems,
        loading,
        pageCount,
        params,
        handlePageClick
    } = useFetchItems(UserServices.addresses.index)

  return (
    <div className={loading ? "dashboard-address isLoading height-100" : "dashboard-address"}>
        <div className="title title-flex">
            <div>
                <h2>{app.localizationsItems?.my_addresses}</h2>
                <span className="title-leaf"></span>
            </div>
            <button className="btn theme-bg-color text-white btn-sm fw-bold mt-lg-0 mt-3" onClick={handleShow}>
                <i className="fa fa-plus me-2"></i>
                {app.localizationsItems?.add_new_address}
            </button>
        </div>

        {items?.items?.length >= 1 ?
            <div className="row g-sm-4 g-3">
                {items.items.map((card, index) => 
                    <div className="col-xxl-4 col-xl-6 col-lg-12 col-md-6" key={index}>
                        <AddressItem card={card} fetchItems={fetchItems}/>
                    </div>
                )}
                <div className='custome-pagination'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
            :
            <div className='mt-3'>
                <h4>{app.localizationsItems?.empty_data}</h4>
            </div>
        }
        {modalOpen && <AddAddressModal show={modalOpen} setShow={setModalOpen} fetchItems={fetchItems}/>}
    </div>
  )
})

export default Address