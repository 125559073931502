import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import UserServices from '../../../services/general/userServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import ReactInputMask from 'react-input-mask';

const EditPasswordModal = observer(({show, setShow, info, fetchInfo}) => {
    const [visible, setVisible] = useState(false);

    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const {handleSubmit, setValue, register, control, formState: {errors}} = useForm();

    useEffect(() => {
        if(info){
            const {...rest} = info;
            for (const [key, value] of Object.entries(rest)) {
                rest[key] !== null && setValue(key, String(value));
            }
        }
    }, [info, setValue])

    const CustomSubmit = async (data) => {
        const res = await UserServices.profile.update(data);

        if (res.statusCode === 200) {
            setVisible(true);
        }
    };

  return (
	<Modal className="modal fade theme-modal" show={show} onHide={handleClose} centered size="lg">
		<Modal.Header>
			<h5 className="modal-title">{app.localizationsItems?.edit_profile}</h5>
			<button type="button" className="btn-close" onClick={handleClose}>
				<i className="fa-solid fa-xmark"></i>
			</button>
		</Modal.Header>
        <form onSubmit={handleSubmit(CustomSubmit)}>
            <Modal.Body>
                <div className="row g-4" >
                    <div className="col-xxl-12">
                        <div className="form-floating theme-form-floating">
                            <input type="password" className="form-control" {...register('password')}/>
                            <label>{app.localizationsItems?.password}</label>
                        </div>
                    </div>
                    <div className="col-xxl-12">
                        <div className="form-floating theme-form-floating">
                            <input type="password" className="form-control" {...register('password')}/>
                            <label>{app.localizationsItems?.password} </label>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <button className="btn btn-animation btn-md fw-bold" onClick={handleClose}>{app.localizationsItems?.cancel}</button>
                <button className="btn theme-bg-color btn-md fw-bold text-light">{app.localizationsItems?.save}</button>
            </Modal.Footer>
        </form>
	</Modal>
  )
})

export default EditPasswordModal