import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap';

const AttributeCollapse = ({card, filters, setFilters, handleFilter, categoryFilters, minValue, maxValue, states}) => {
    const [open, setOpen] = useState(true);

    /*const changeFilter = (elem) => {
        let mas = [];
        if(filters.find(it => it.value === elem.value && it.key === card.key)){
            let arr = filters.filter(it => (it.value !== elem.value) || (it.key !== card.key))
            setFilters(arr)
            mas = [...arr]
        }else{
            setFilters([...filters, {key: card.key, value: elem.value}])
            mas = [...filters, {key: card.key, value: elem.value}];
        }
        handleFilter(categoryFilters, minValue, maxValue, states, mas);
    }*/

    const changeFilter = (elem) => {
        const filterExists = filters.some(it => it.value === elem.value && it.key === card.key);
    
        let mas;
    
        if (filterExists) {
            const updatedFilters = filters.filter(it => it.value !== elem.value || it.key !== card.key);
            setFilters(updatedFilters);
            mas = updatedFilters;
        } else {
            const newFilter = { key: card.key, value: elem.value };
            setFilters([...filters, newFilter]);
            mas = [...filters, newFilter];
        }
    
        handleFilter(categoryFilters, minValue, maxValue, states, mas);
    }

  return (
    card.elements?.length >= 1 &&
    <div className="accordion-item">
        <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button" type="button" aria-expanded={open}
                aria-controls="collapseFive"
                onClick={() => setOpen(!open)}>
                <span>{card.title}</span>
            </button>
        </h2>
        <Collapse in={open} className="accordion-collapse">
            <div className="accordion-body" id="collapseFive">
                <ul className="category-list custom-padding custom-height2">
                    {card.elements.map((elem, index) =>
                        <li key={index}>
                            <div className="form-check ps-0 m-0 category-list-box">
                                <label className="form-check-label" onClick={() => changeFilter(elem)}>
                                    <div className={filters.find(it => it.value === elem.value && it.key === card.key) ? 'checkbox_animated clicked' : 'checkbox_animated'}></div>
                                    <span className="name">{elem.name}</span>
                                </label>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </Collapse>
    </div>
  )
}

export default AttributeCollapse