import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import useFetchItems from '../../../hooks/useFetchItems';
import { Context } from '../../..';
import ReviewsBox from './ReviewsBox';
import ReviewsServices from '../../../services/general/reviewsServices';
import Pagination from '../../../components/complex/Pagination';

const Reviews = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
        fetchItems,
        pageCount,
        params,
        handlePageClick
    } = useFetchItems(ReviewsServices.index)

  return (
    <div className={loading ? "dashboard-order isLoading height-100" : "dashboard-order"}>
        <div className="title">
            <h2>{app.localizationsItems?.my_reviews}</h2>
            <span className="title-leaf title-leaf-gray"></span>
        </div>
        {items?.items?.length >= 1 ?
            <div className="order-contain">
                {items.items.map((card, index) =>
                    <ReviewsBox card={card} key={index} fetchItems={fetchItems}/>
                )}
                <div className='custome-pagination'>
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </div>
            </div>
            :
            <div>
                <h4>{app.localizationsItems?.empty_data}</h4>
            </div>
        }
    </div>
  )
})

export default Reviews