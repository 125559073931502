import React, { useContext } from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices';
import Utils from '../../services/utils';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import DeleteProductModal from '../modals/DeleteProductModal';
import { toast } from 'react-toastify';

const CartIconItem = observer(({card}) => {
    const {app, user} = useContext(Context);
    const [deleteModal, setDeleteModal] = useState();

    const [loading, setLoading] = useState();

    const deleteItem = async () => {
        setLoading(true)
        const data = {
            uid: user.uid,
            product_id: card.id,
            fields: card.fields,
            price_config: card.price_config
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
            setLoading(false);
            setDeleteModal(false);
        }else{
            toast.error(res.message);
        }
    }

  return (
    <li className="product-box-contain">
        <div className={loading ? "drop-cart isLoading" : "drop-cart"}>
            <NavLink className="drop-image" to={`/${app.lang}/products/${card.slug}`}>
                <img src={card.image} alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            </NavLink>

            <div className="drop-contain">
                <NavLink to={`/${app.lang}/products/${card.slug}`}>
                    <h5>{card.title}</h5>
                </NavLink>
                <h6><span>{card.count} x</span> 
                    {(!card.price || card.price == 0) ?
                        <>{app.localizationsItems?.not_specified}</>
                        :
                        <>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</>
                    }
                </h6>
                <button className="close-button close_button" onClick={() => setDeleteModal(true)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
        </div>
        {deleteModal && <DeleteProductModal show={deleteModal} setShow={setDeleteModal} deleteItem={deleteItem}/>}
    </li>
  )
})

export default CartIconItem