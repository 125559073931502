import React from 'react'
import { Modal } from 'react-bootstrap'
import { observer } from 'mobx-react-lite';

const ProductDropdownModal = observer(({show, setShow, description}) => {

    const handleClose = () => setShow(false);
  return (
    <Modal show={show} onHide={handleClose} centered className='dropdown-modal'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div dangerouslySetInnerHTML={{__html: description}}></div>
        </Modal.Body>
    </Modal>
  )
})

export default ProductDropdownModal