import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop'
import UserContainer from './component/UserContainer'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const User = observer(() => {
    const {app} = useContext(Context);

    const item = {
        title: app.localizationsItems?.profile
    }
  return (
    <div>
        <PageTop item={item}/>
        <UserContainer/>
    </div>
  )
})

export default User