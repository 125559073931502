import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import img from "../../images/placeholder.png"
import { useNavigate } from 'react-router-dom';

const BannerSection2 = ({item}) => {
	const [banner1, setBanner1] = useState();

    const navigate = useNavigate();

	useEffect(() => {
		if(item){
			setBanner1(item.elements[0])
		}
	}, [item])

  return (  
	banner1 &&
	<section className="banner-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
					<div className="banner-contain-3 section-b-space section-t-space hover-effect">
						<img src={banner1.image} className="img-fluid bg-img position-absolute" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
						<div className="banner-detail p-center text-dark position-relative text-center p-0">
							<div style={{color: banner1.banner_font_color}}>
								<h4 className="ls-expanded text-uppercase theme-color" dangerouslySetInnerHTML={{__html: banner1.subtitle_top}}></h4>
								<h2 className="my-3">{banner1.title}</h2>
								<h4 className="fw-300">{banner1.description}</h4>
								{banner1.link && <button onClick={() => navigate(banner1.link)} className="btn theme-bg-color mt-sm-4 btn-md mx-auto text-white fw-bold">{banner1.subtitle}</button>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default BannerSection2