import React from 'react'
import EventSidebar from '../simple/EventSidebar'
import img from "../../images/placeholder.png"

const EventDetailContainer = ({item}) => {
  return (
	<section className="blog-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row g-sm-4 g-3">
				<div className="col-xxl-3 col-xl-4 col-lg-5 d-lg-block d-none">
                    <EventSidebar/>
                </div>

                <div className="col-xxl-9 col-xl-8 col-lg-7 ratio_50">
                    <div className="blog-detail-image rounded-3 mb-4">
                        <img src={item.detail_image} className="bg-img" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                        />
                        <div className="blog-image-contain">
                            <ul className="contain-list">
                                <li>{item.subtitle}</li>
                            </ul>
                            <h2>{item.title}</h2>
                            <ul className="contain-comment-list">
                                <li>
                                    <div className="user-list">
                                        <i data-feather="calendar"></i>
                                        <span>{item.date}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="blog-detail-contain">
                        <p dangerouslySetInnerHTML={{ __html: item.description}}></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default EventDetailContainer