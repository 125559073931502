import React, { useContext } from 'react'
import PageTop from '../../components/simple/PageTop';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import { useNavigate, useParams } from 'react-router-dom';
import EventsServices from '../../services/general/eventServices';
import EventDetailContainer from '../../components/complex/EventDetailContainer';
import { useEffect } from 'react';
import AboutUs from '../../components/complex/AboutUs';
import Advantages from '../../components/complex/Advantages';
import OurTeam from '../../components/complex/OurTeam';
import ReviewSection from '../../components/complex/ReviewSection';
import FaqComponent from '../../components/complex/FaqComponent';
import OurBusiness from '../../components/complex/OurBusiness';
import Excellences from '../../components/complex/Excellences';
import OurBlog from '../../components/complex/OurBlog';
import BannerSection from '../../components/simple/BannerSection';
import BannerSection2 from '../../components/simple/BannerSection2';
import Newsletter from '../../components/complex/Newsletter';
import DayOffer from '../../components/complex/DayOffer';
import ProductsGrid from '../../components/complex/ProductsGrid';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const EventDetail = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/events/${slug}`);
            }
        }
    }, [app.lang])

    const {info, loading} = useFetchInfo(EventsServices.view, slug);

  return (
    <>
    {loading ?
        <div className="fullpage-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        :
    <div>
        <PageTop item={info}/>
        {info && <EventDetailContainer item={info}/>}
        {info?.elements?.map((elem, index) =>
            <div key={index}>
                {elem.type === "about" ?
                    <AboutUs elem={elem}/>
                    :
                elem.type === "advantages" ?
                    <Advantages item={elem}/>
                    :
                elem.type === "workers" ?
                    <OurTeam elem={elem}/>
                    :
                elem.type === "reviews" ?
                    <ReviewSection elem={elem}/>
                    :
                elem.type === "faq" ?
                    <FaqComponent elem={elem}/>
                    :
                elem.type === "advantages-2" ?
                    <OurBusiness elem={elem}/>
                    :
                elem.type === "steps" ?
                    <Excellences elem={elem}/>
                    :
                elem.type === "articles" ?
                    <OurBlog elem={elem}/>
                    :
                elem.type === "advertising-banner-2" ?
                    Number(elem.banner_type) === 1 ?
                        <BannerSection item={elem}/>
                        :
                    Number(elem.banner_type) === 2 ?
                        <BannerSection2 item={elem}/>
                        :
                    Number(elem.banner_type) === 3 ?
                        <Newsletter item={elem}/>
                        :
                        <></>
                    :
                elem.type === "products" ?
                    elem.products_type === "recommended" ?
                        <DayOffer item={elem}/>
                        :
                        <ProductsGrid item={elem}/>
                    :
                    <></>
                }
            </div>
        )}
    </div>
    }</>
  )
})

export default EventDetail