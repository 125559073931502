import React, { useContext, useEffect } from 'react'
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const PriceToddle = observer(({items, minValue, setMinValue, maxValue, setMaxValue, filters, handleFilter}) => {
    const {app} = useContext(Context);
    const [open, setOpen] = useState(true);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState();

    useEffect(() => {
        if(items){
            setMax(items.price_max)
            setMin(items.price_min)
            setMaxValue(items.price_max)
            setMinValue(items.price_min)
        }
    }, [items])

    const handleMinUpdate = (value) => {
        var slider = document.getElementById('uid-slider');
        slider.noUiSlider.set([value, maxValue]);

        setMinValue(value);
        handleFilter(filters, value, maxValue);
    }

    const handleMaxUpdate = (value) => {
        var slider = document.getElementById('uid-slider');
        slider.noUiSlider.set([minValue, value]);

        setMaxValue(value);
        handleFilter(filters, minValue, value);
    }

    var format = {
        to: function(value) {
            return parseInt(value);
        },
        from: function (value) {
            return parseInt(value);
        }
    };

    const handleChange = (e) => {
        setMinValue(e[0]);
        setMaxValue(e[1]);
        handleFilter(filters, e[0], e[1]);
    }

  return (
    (max > min) ?
    <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" aria-expanded={open}
                aria-controls="collapseOne"
                onClick={() => setOpen(!open)}
                >
                <span>{app.localizationsItems?.price}</span>
            </button>
        </h2>
        <Collapse in={open} className="accordion-collapse">
            <div className="accordion-body" id="collapseTwo" >
                <div className="range-slider">
                    <div className="filter-price">
                        <Nouislider
                            id='uid-slider'
                            range={{ min: min, max: max }} 
                            start={[min, max]} 
                            connect
                            tooltips = {true}
                            format = {format}
                            onChange={e => handleChange(e)}
                        />
                        <div className="row">
                            <div className="col-6">
                                <input type="number" className="js-range-slider irs-hidden-input" value={minValue} onChange={e => handleMinUpdate(e.target.value)}/>
                            </div>
                            <div className="col-6">
                                <input type="number" className="js-range-slider irs-hidden-input" value={maxValue} onChange={e => handleMaxUpdate(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    </div>
    :
    <></>
  )
})

export default PriceToddle